import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getCreditLimitsPageParamsSchema } from 'src/pages/shippers/credit-limits/schema';

export function useCreditLimitsPageParams() {
  return usePageParamsFromSchema(getCreditLimitsPageParamsSchema(), {
    filters: {
      creditLimitIdentifier: { label: 'Credit Limit ID' },
      frameworkLabels: { label: 'Framework' },
      shippers: { label: 'Shipper' },
      intervalStart: { label: 'Interval' },
      intervalEnd: { label: 'Interval' },
      createdAtFrom: { label: 'Created at' },
      createdAtTo: { label: 'Created at' },
      changedAtFrom: { label: 'Last modified at' },
      changedAtTo: { label: 'Last modified at' },
    },
  });
}

export type CreditLimitsPageParams = ReturnType<
  typeof useCreditLimitsPageParams
>;
