import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { TsoAssignmentDocuments } from 'src/apis/shipper-registration/types';
import { ShipperRegistrationPublicRequest } from 'src/apis/shipper-registration/use-shipper-registration';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type AssignmentDocumentsOptions = {
  shipperRegistrationPublic: ShipperRegistrationPublicRequest;
  tsoId: string;
};

export function useTsoAssignmentDocuments({
  tsoId,
  shipperRegistrationPublic,
}: AssignmentDocumentsOptions) {
  const documents = useAxios(
    (axios, baseConfig, { url, tsoId }: { url: string; tsoId: string }) =>
      axios
        .request<TsoAssignmentDocuments>({
          ...baseConfig,
          url: urlTemplate.parse(url).expand({ tsoId }),
          method: 'GET',
        })
        .catch((error) => {
          if (isServerError(error, 404)) {
            // for backwards compatible reasons, we treat 404 as successful,
            // but at least we type the response correctly (in case the type ever
            // changes and requires mandatory fields)
            const response =
              error.response as AxiosResponse<TsoAssignmentDocuments>;
            response.data = {};
            return response;
          } else {
            throw error;
          }
        }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!shipperRegistrationPublic.response) return;
    const url =
      shipperRegistrationPublic.response.data._links.tsoAssignmentDocuments
        .href;
    documents.execute({ url, tsoId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tsoId, shipperRegistrationPublic.response]);

  return documents;
}

export type AssignmentDocumentsRequest = ReturnType<
  typeof useTsoAssignmentDocuments
>;
