import { FC, lazy, useMemo } from 'react';
import { AssignedUsersParams } from 'src/apis/assignment-service/types';
import { useAssignedUsers } from 'src/apis/assignment-service/use-assigned-users';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useAssignedUsersPageParams } from 'src/pages/shippers/users/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('src/pages/shippers/users/page'));

type Props = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const AssignedUsers: FC<Props> = ({ assignmentService }) => {
  if (!assignmentService.response.data._links.assignedUsers)
    throw new Forbidden();
  return <AssignedUsersTable assignmentService={assignmentService} />;
};

export const AssignedUsersTable: FC<Props> = ({ assignmentService }) => {
  const pageParams = useAssignedUsersPageParams();
  const params = useMemo(() => {
    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      name: pageParams.value.name,
      shipperName: pageParams.value.shipperName,
      shipperEic: pageParams.value.shipperEic,
      email: pageParams.value.email,
      role: pageParams.value.role,
      userAssignmentStatus: pageParams.value.userAssignmentStatus,
      organisationAssignmentStatus:
        pageParams.value.organisationAssignmentStatus,
      userStatus: pageParams.value.userStatus,
      tradingMarket: pageParams.value.tradingMarket,
    } satisfies AssignedUsersParams;
  }, [pageParams.value]);

  const assignedUsers = useAssignedUsers({
    url: assignmentService.response.data._links.assignedUsers!.href,
    params,
  });

  if (!assignedUsers.response) return <PageSpinner />;

  return <Page assignedUsers={assignedUsers} pageParams={pageParams} />;
};
