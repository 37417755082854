import { useEffect } from 'react';
import { RegularFcfsRoot } from 'src/apis/regular-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export const regularFcfsRootCacheKey = 'regular-fcfs-root';

export function useRegularFcfsRoot() {
  const regularFcfsRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<RegularFcfsRoot>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.regularFcfs}/api`,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: regularFcfsRootCacheKey,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    regularFcfsRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return regularFcfsRoot;
}

export type RegularFcfsRootRequest = ReturnType<typeof useRegularFcfsRoot>;
