import {
  Select,
  SelectItem,
  SelectPopover,
  SelectProps,
  SelectProvider,
  useSelectStore,
} from '@ariakit/react';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useEffect, useId, useRef } from 'react';
import { RectReadOnly } from 'react-use-measure';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { useFormMode } from 'src/components/form/form-mode-context';
import { InnerAddon } from 'src/components/form/inner-addon';
import { FieldLabel } from 'src/components/form/label';
import { SelectOption } from 'src/components/form/select';
import { RemoveButton } from 'src/components/form/select/components/remove-button';
import { SelectToggleIcon } from 'src/components/form/select/components/select-toggle';
import { Separator } from 'src/components/form/select/components/separator';
import {
  applySortBy,
  selectOptionBaseStyle,
  SortByOptions,
} from 'src/components/form/select/utils';
import { borderWidth, placeholderStyle } from 'src/components/form/styles';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import {
  Colors,
  disabledFocusStyle,
  flyoutStyles,
  focusStyle,
} from 'src/styles';
import styled, { css } from 'styled-components';

type SingleSelectProps<Value extends string | null = string> = {
  stacked?: boolean;
  inline?: boolean;
  initialOpen?: boolean;
  /**
   * If you have an option with the value `null` this indicates that the field
   * can be emptied (via our remove button) even though it is not directly shown in the list of options.
   * Furthermore the label of this option will be used as placeholder.
   */
  options: SelectOption<Value>[];
  onChange?: (value: Value) => void;
  placeholder?: string;
  sortBy?: SortByOptions;
} & FormFieldProps;

export function SingleSelect<Value extends string | null = string>(
  props: SingleSelectProps<Value>
) {
  const { isStacked, minTablet } = useDefaultStacked();
  const {
    name,
    label,
    onChange,
    placeholder,
    stacked = isStacked,
    inline,
    options,
    hideLabel = false,
    disabled,
    disabledMessage,
    hint,
    info,
    sortBy = 'label',
    markAsRequired,
    initialOpen,
  } = props;

  const [field, showError, error, helper] = useCustomField<Value>(name, label);

  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const id = useId();
  const fieldId = formGroup?.id || id;
  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  const fieldItem = (
    <FieldItem>
      <Tooltip
        content={disabled && disabledMessage ? disabledMessage : undefined}
      >
        {(targetProps) => (
          <SimpleSingleSelect
            id={fieldId}
            options={options}
            showError={showError}
            placeholder={placeholder}
            disabled={disabled}
            {...field}
            onChange={(value) => {
              helper.setValue(value);
              onChange?.(value);
            }}
            value={field.value}
            sortBy={sortBy}
            initialOpen={initialOpen}
            {...targetProps}
          />
        )}
      </Tooltip>

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked} inline={inline}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
}

type SimpleSingleSelectProps<Value extends string | null = string> = {
  id?: string;
  name: string;
  showError?: boolean;
  placeholder?: string;
  options: SelectOption<Value>[];
  disabled?: boolean;
  onChange: (value: Value) => void;
  value: Value;
  sortBy?: SortByOptions;
  initialOpen?: boolean;
};

export function SimpleSingleSelect<Value extends string | null = string>({
  id,
  name,
  showError,
  disabled,
  value,
  onChange,
  options: unsortedOptions,
  placeholder = 'Please Select',
  sortBy = 'label',
  initialOpen,
}: SimpleSingleSelectProps<Value>) {
  const options = applySortBy(sortBy, unsortedOptions);
  const hasSingleOption = options.length === 1;
  const singleOptionValue = hasSingleOption ? options[0].value : false;

  // for backwards compatibility, we need to support both empty strings and null as empty values
  const emptyOptionAvailable =
    options[0]?.value === '' || options[0]?.value === null; // when ordered empty value would be the first one
  // it can also happen that a select value is required and can only be empty initially,
  // but not picked from the list of options.
  const initialEmptyValueRef = useRef<Value | null>(
    value === '' || value === null ? value : null // fallback to null
  );
  const emptyValue = emptyOptionAvailable
    ? options[0]?.value
    : initialEmptyValueRef.current;

  const isDisabled = options.length === 0 || hasSingleOption || disabled;

  // prefer label of empty value as placeholder
  const chosenPlaceholder = emptyOptionAvailable
    ? options[0]?.label
    : placeholder;

  const mode = useFormMode();
  const isForm = mode === 'regular';

  const focusRef = useFocusManager<HTMLButtonElement>({ type: 'click' });

  // in case the value is required, but the initial value is empty it can also happen
  // that there is no selected item at all.
  // in those cases we show the placeholder.
  const selectedItem = options.find((item) => item.value === value);

  // auto-select single option
  useEffect(() => {
    if (singleOptionValue === false) return;
    if (value !== singleOptionValue) {
      onChange(singleOptionValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, singleOptionValue]);

  const showRemoveButton =
    emptyOptionAvailable &&
    options.length > 0 &&
    selectedItem &&
    selectedItem.value !== emptyValue &&
    isForm;

  const store = useSelectStore({
    value: value ?? '',
    setValue(value) {
      onChange((value === emptyValue ? emptyValue : value) as Value);
    },
    defaultOpen: initialOpen,
  });
  const isOpen = store.useState('open');
  const activeId = store.useState('activeId');

  return (
    <div>
      {/*
        for backwards compability: by using <input type="hidden"/> we can access the value easily in tests.
        this is needed since we switched from using a native <select /> to a custom select component.
      */}
      <input type="hidden" data-testid={name} value={value ?? ''} />

      <SelectProvider store={store}>
        <InnerAddon
          right={
            <Stack flow="column" gap={0.5}>
              {showRemoveButton && (
                <Stack flow="column" gap={0.5}>
                  <RemoveButton
                    error={showError}
                    disabled={isDisabled}
                    data-testid={`${name}-remove-selection`}
                    onClick={() => {
                      onChange(emptyValue as Value);
                    }}
                    style={{ pointerEvents: 'initial' }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </RemoveButton>
                  <Separator error={showError} disabled={isDisabled} />
                </Stack>
              )}

              <SelectToggleIcon
                showError={showError}
                disabled={isDisabled}
                isOpen={isOpen}
              />
            </Stack>
          }
        >
          {(addonBounds) => (
            <ToggleButton
              id={id}
              ref={focusRef}
              error={showError}
              disabled={isDisabled}
              data-name={name} // for focus manager
              data-testid={`${name}-togglebutton`}
              data-test-error={showError}
              data-style-is-placeholder={
                selectedItem ? selectedItem.value === emptyValue : true
              }
              {...addonBounds}
            >
              <ToggleButtonContent>
                {selectedItem ? selectedItem.label : chosenPlaceholder}
              </ToggleButtonContent>
            </ToggleButton>
          )}
        </InnerAddon>

        <Popover
          unmountOnHide
          style={{ zIndex: 1 }}
          data-testid={`${name}-options`}
        >
          {options.map((option) => (
            <ItemWithTooltips
              key={option.value}
              activeId={activeId}
              option={option}
              emptyValue={emptyValue}
              value={value}
              id={id}
              showError={showError}
              name={name}
            />
          ))}
        </Popover>
      </SelectProvider>
    </div>
  );
}

function ItemWithTooltips<Value extends string | null = string>({
  id,
  activeId,
  option,
  value,
  emptyValue,
  name,
}: {
  id?: string;
  activeId: string | null | undefined;
  option: SelectOption<Value>;
  value: string | null | undefined;
  emptyValue: Value | null | undefined;
  showError?: boolean;
  name: string;
}) {
  const infoRef = useRef<HTMLElement>(null);
  const itemTestId = `${name}-option-${option.value}`;
  const itemId = `${id}-${itemTestId}`;

  const active = activeId === itemId;

  if (option.value === emptyValue) return null;

  return (
    <Tooltip
      key={option.value}
      content={option.disabled && option.disabledMessage}
      noTabIndex
      controlActive={active}
    >
      {(disabledMessageTargetProps) => (
        <Tooltip
          content={option.info}
          positionRef={infoRef}
          noTabIndex
          controlActive={active}
        >
          {(infoTargetProps) => (
            <Item
              {...infoTargetProps}
              {...disabledMessageTargetProps}
              disabled={option.disabled}
              value={option.value ?? ''}
              accessibleWhenDisabled // make it possible to discover disabled items via keyboard
              id={itemId}
              data-testid={itemTestId}
            >
              <Stack
                flow="column"
                justifyContent="space-between"
                alignItems="baseline"
                gap={2}
              >
                <span>
                  {option.label}
                  {option.info && (
                    <>
                      {' '}
                      <FontAwesomeIcon
                        forwardedRef={infoRef}
                        icon={faInfoCircle}
                        color={Colors.brand}
                      />
                    </>
                  )}
                </span>

                {option.value && option.value === value && (
                  <FontAwesomeIcon
                    data-testid={`${itemTestId}-isSelected`}
                    icon={faCheck}
                  />
                )}
              </Stack>

              {option.hint && option.value !== value && (
                <Hint
                  disabled={option.disabled}
                  mode="formInput"
                  children={option.hint}
                />
              )}
            </Item>
          )}
        </Tooltip>
      )}
    </Tooltip>
  );
}

const ToggleButtonContent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ToggleButton = styled(
  forwardRef<
    HTMLButtonElement,
    SelectProps & {
      error?: boolean;
      leftBounds?: RectReadOnly;
      rightBounds?: RectReadOnly;
    }
  >(({ error, leftBounds, rightBounds, ...props }, ref) => (
    <Select {...props} ref={ref} />
  ))
)`
  appearance: none;
  /* see https://github.com/ariakit/ariakit/issues/845#issuecomment-785493623 */
  pointer-events: initial !important;
  text-align: left;
  background: white;
  /* white-space: nowrap; */
  text-overflow: clip;
  margin: 0;
  display: inline-grid;
  overflow: hidden;
  cursor: default;
  align-items: center;
  justify-content: space-between;
  /* mirror SimpleInput style: */
  width: 100%;
  border: ${borderWidth} solid ${Colors.brand};
  color: ${Colors.brand};
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: ${({ leftBounds }) =>
    leftBounds ? leftBounds.width + 'px' : '2rem'};
  padding-right: ${({ rightBounds }) =>
    rightBounds ? rightBounds.width + 'px' : '2rem'};

  &[data-style-is-placeholder='true'] {
    ${placeholderStyle}
  }

  &[aria-disabled] {
    border-color: ${Colors.inactive};
    background: ${Colors.inactiveLighter};
    cursor: not-allowed;
    color: ${Colors.inactive};

    &[data-style-is-placeholder='true'] {
      color: ${Colors.inactive};
    }
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};

      &[data-style-is-placeholder='true'] {
        color: ${Colors.error};
      }
    `}

  :focus-visible {
    ${focusStyle};
  }
`;

const Popover = styled(SelectPopover)`
  ${flyoutStyles}
  left: 0;
  right: 0;
  min-width: 17.9rem;
  width: var(--popover-anchor-width);
  max-height: 23.5rem;
  overflow: auto;

  :focus-visible {
    /* by default ariakit keeps the popover focused and uses the
    activedescendant pattern to manage a virtualfocus for the active item.
    we handle the focus style for them manually. */
    outline: none;
  }
`;

const Item = styled(SelectItem)`
  ${selectOptionBaseStyle};

  &[aria-selected='true'] {
    background-color: ${Colors.brandLight2};
    color: white;

    &[data-style-is-placeholder='true'] {
      color: ${Colors.brandLight4};
    }
  }

  &[data-active-item]:not([aria-selected='true'], [aria-disabled]) {
    background-color: ${Colors.background};
    color: ${Colors.brand};

    &[data-style-is-placeholder='true'] {
      ${placeholderStyle}
    }
  }

  &[aria-disabled] {
    border-color: ${Colors.inactive};
    background: ${Colors.inactiveLighter};
    color: ${Colors.inactive};
    cursor: not-allowed;
  }

  &[data-style-is-placeholder='true'] {
    ${placeholderStyle}
  }

  [data-focus-visible='true'] &[data-active-item] {
    ${disabledFocusStyle};

    &:not([aria-disabled]) {
      ${focusStyle};
    }
  }
`;
