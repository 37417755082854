import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { CancelOrganizationAssignmentButton } from 'src/pages/operators/assignments/details/components/cancel-organization-assignment-button';
import { AssignmentStatusDisplayMonolith } from 'src/pages/operators/assignments/overview/components/assignment-status-monolith';
import { AssignmentsOrganisationItem } from 'src/pages/operators/assignments/overview/types';

type Props = {
  orgAssignment: AssignmentsOrganisationItem & { transferCompanyData: boolean };
  caMaOperator?: OperatorRequest;
  tsoId: string;
  showAssignmentsButton?: boolean;
  title: string;
  onCancelSuccess?: () => void;
};

export const AssignmentDetailsOrganisationStatus: FC<Props> = ({
  orgAssignment,
  caMaOperator,
  tsoId,
  showAssignmentsButton = true,
  title,
  onCancelSuccess,
}) => {
  const currentUser = useAuthenticatedMonolithUser();
  return (
    <Card data-testid="organisation-assignment-card">
      <Stack gap={1} alignItems="center">
        <Stack gap={1} autoColumns="1fr auto" flow="column">
          <Heading mode="card">{title}</Heading>
          {showAssignmentsButton
            ? currentUser.role === 'SHIPPER_ADMIN' &&
              (orgAssignment.status === 'APPROVED' ||
              orgAssignment.status === 'NOT_APPROVED' ? (
                <CancelOrganizationAssignmentButton
                  tsoId={tsoId}
                  assignedOrganisation={orgAssignment}
                  onCancelSuccess={onCancelSuccess}
                />
              ) : null)
            : null}
        </Stack>
        <Stack flow="row" gap={1} justifyContent="space-between">
          <AssignmentStatusDisplayMonolith
            value={orgAssignment.status}
            {...(orgAssignment.marketAccess && {
              marketAccess: orgAssignment.marketAccess,
            })}
            data-testid="organisation-assignment-status"
          />

          {(caMaOperator?.response?.data ||
            orgAssignment.status === 'NOT_APPROVED') && (
            <div data-testid="organisation-assignment-info">
              <DataList>
                <DataItems>
                  {orgAssignment.status === 'NOT_APPROVED' && (
                    <>
                      <span>MAC Notification</span>
                      <span>
                        {orgAssignment.transferCompanyData
                          ? 'Has been sent'
                          : 'Has not been sent'}{' '}
                        <Tooltip
                          content={`Market Area Coordinator has${
                            orgAssignment.transferCompanyData ? '' : ' NOT'
                          } been notified about the assignment request via email.`}
                        >
                          {(targetProps) => (
                            <FontAwesomeIcon
                              {...targetProps}
                              icon={faQuestionCircle}
                            />
                          )}
                        </Tooltip>
                      </span>
                    </>
                  )}
                  {!!caMaOperator?.response?.data.allowsNomination && (
                    <>
                      <span>Nomination</span>
                      <span>Allowed</span>
                    </>
                  )}
                </DataItems>
              </DataList>
            </div>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
