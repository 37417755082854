import { FC, lazy } from 'react';
import { InvoicesParamsForTso } from 'src/apis/capacity-management/types';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useInvoices } from 'src/apis/capacity-management/use-invoices';
import { Card } from 'src/components/data-display/card';
import { Alert } from 'src/components/feedback/alert';
import { PageSpinner } from 'src/components/spinner-container';
import { UsePortal } from 'src/hooks/use-portal';
import { usePageParams } from 'src/pages/shippers/invoices/schema';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type Props = {
  actionPortal: UsePortal;
};

export const InvoicesOverview: FC<Props> = ({ actionPortal }) => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const pageParams = usePageParams();

  const params = useMemoOne<InvoicesParamsForTso>(() => {
    const {
      start,
      pageSize,
      shipperId,
      contractId,
      invoiceType,
      monthlyStatementType,
      monthlyStatementId,
      ...rest
    } = pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      shipperId: shipperId.map((item) => item.value),
      contractId: contractId.map((item) => item.value),
      monthlyStatementId: monthlyStatementId.map((item) => item.value),
      invoiceType,
      monthlyStatementType,
      ...rest,
    };
  }, [pageParams.value]);
  const invoices = useInvoices({ capacityManagementRoot, params });

  if (invoices.error)
    return (
      <Card>
        <Alert type="warning">
          The Invoices feature can only be used if you are using the PRISMA
          Invoicing Service.
        </Alert>
      </Card>
    );

  if (!invoices.response) return <PageSpinner />;

  return (
    <Page
      invoices={invoices}
      pageParams={pageParams}
      actionPortal={actionPortal}
    />
  );
};
