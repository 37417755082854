import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { Divider } from 'src/components/dividers';
import { ErrorHandler } from 'src/components/form/error-handler';
import {
  formGap,
  SimpleFormContainer,
} from 'src/components/form/form-container';
import { FormModeContextValue } from 'src/components/form/form-mode-context';
import {
  toFormikValidate,
  validFormValues,
} from 'src/components/form/zod-utilities';
import { Stack, StackProps } from 'src/components/layout/stack';
import { useMeasure } from 'src/hooks/use-measure';
import { ConstraintViolation } from 'src/utils/is-constraint-violation';
import { z } from 'zod';

export type FormProps<Schema extends z.Schema> = {
  enableReinitialize?: boolean;
  initialValues: z.input<Schema>;
  schema: Schema | ((values: z.input<Schema>) => Schema);
  onSubmit: (
    values: z.output<Schema>,
    formikHelpers: FormikHelpers<z.input<Schema>>
  ) => void;
  onReset?: (
    values: z.input<Schema>,
    formikHelpers: FormikHelpers<z.input<Schema>>
  ) => void;
  children: ReactNode | ((props: FormikProps<z.input<Schema>>) => ReactNode);
  /**
   * Form fields might behave slightly differently depending on the `mode` of our form.
   * Currently we have three cases:
   * - regular: Your regular form usually used to submit data.
   * - filter: A form which is used for filtering data.
   * - search: A form which is used for searching data.
   */
  mode?: FormModeContextValue;
  gap?: number;
  constraintViolation: ConstraintViolation | null;
  /**
   * You should only hide the default toast for constraint violations, if it is managed somewhere else.
   * This can happen for example inside wizards, where you want to show a toast
   * _once_, but every step has its own error handler.
   */
  hideToastOnConstraintViolation?: boolean;
  /**
   * If you don't have a cancel or reset button, just pass `null`.
   */
  cancelOrResetButton:
    | ReactNode
    | ((props: FormikProps<z.input<Schema>>) => ReactNode);
  /**
   * Be careful if you pass `null`! It is extremely rare that you don't have a submit button.
   */
  submitButton:
    | ReactNode
    | ((props: FormikProps<z.input<Schema>>) => ReactNode);
  'data-testid'?: string;
  /**
   * If the <Form/> children need to be rendered inside a special container (e.g. for animations)
   * it can be added here. Be careful to not divirge too much from the default behavior.
   */
  container?: ({
    props,
    children,
  }: {
    props: FormikProps<z.input<Schema>>;
    children: ReactNode;
  }) => ReactNode;
};

export function Form<Schema extends z.Schema>({
  mode = 'regular',
  /**
   * In case of filters we enable reinitialization by default.
   * This will update the form on back and forward navigation as
   * the values are derived from query params.
   */
  enableReinitialize = mode === 'filter',
  initialValues,
  schema,
  onSubmit,
  onReset,
  children,
  constraintViolation,
  hideToastOnConstraintViolation,
  cancelOrResetButton,
  submitButton,
  gap = formGap,
  'data-testid': testid,
  container = ({ children }) => children,
}: FormProps<Schema>) {
  const [containerRef, containerBounds] = useMeasure();

  // amount of filter columns is dynamic and depends on the width of the container
  const containerWidth = containerBounds.width;
  const filterColumns = Math.floor(containerWidth / 260) || 1; // at least 1 column

  const stackProps: StackProps =
    mode === 'filter'
      ? {
          gap,
          alignItems: 'baseline',
          // `1fr` is the short form of `minmax(auto, 1fr)`, but using `minmax(0, 1fr)`
          // prevents form fields from overflowing.
          templateColumns: 'minmax(0, 1fr) '.repeat(filterColumns),
        }
      : { gap };

  const getActualSchema = (values: z.input<Schema>) => {
    return typeof schema === 'function' ? schema(values) : schema;
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validate={(values) => {
        return toFormikValidate(getActualSchema(values), values);
      }}
      onSubmit={async (values, formikHelpers) =>
        onSubmit(
          await validFormValues(getActualSchema(values), values),
          formikHelpers
        )
      }
      onReset={onReset}
    >
      {(props) =>
        container({
          props,
          children: (
            <SimpleFormContainer mode={mode} data-testid={testid}>
              <Stack gap={mode === 'filter' ? 0 : formGap}>
                <Stack ref={containerRef} {...stackProps}>
                  {typeof children === 'function' ? children(props) : children}
                </Stack>

                <ErrorHandler
                  serverError={constraintViolation}
                  hideToastOnServerError={hideToastOnConstraintViolation}
                />

                {mode === 'filter' && <Divider />}

                {Boolean(cancelOrResetButton || submitButton) && (
                  <Stack flow="column" gap={1} justifyContent="space-between">
                    {(typeof cancelOrResetButton === 'function'
                      ? cancelOrResetButton(props)
                      : cancelOrResetButton) || <span />}

                    {typeof submitButton === 'function'
                      ? submitButton(props)
                      : submitButton}
                  </Stack>
                )}
              </Stack>
            </SimpleFormContainer>
          ),
        })
      }
    </Formik>
  );
}
