import { FC, lazy } from 'react';
import {
  AssignedShippersRequestParams,
  useAssignedShippersPage,
} from 'src/apis/monolith/use-assigned-shippers-page';
import { PageSpinner } from 'src/components/spinner-container';
import { useShipperOverviewPageParams } from 'src/pages/shippers/organisations/use-page-params';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

export const ShippersListOverview: FC = () => {
  const pageParams = useShipperOverviewPageParams();

  const requestParams = useMemoOne<AssignedShippersRequestParams>(
    () => ({
      start: pageParams.value.start,
      pageSize: pageParams.value.pageSize,
      shipperName: pageParams.value.shipperName,
      eic: pageParams.value.eic ?? undefined,
      state: pageParams.value.state,
      primaryAccess: pageParams.value.primaryAccess,
      secondaryAccess: pageParams.value.secondaryAccess,
      sortAscending: pageParams.value.sortAscending,
      sortColumn: pageParams.value.sortColumn,
    }),
    [pageParams.value]
  );
  const shippers = useAssignedShippersPage(requestParams);

  if (!shippers.response) return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      requestParams={requestParams}
      shippers={shippers}
    />
  );
};
