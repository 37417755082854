import { endOfMinute, endOfSecond, startOfSecond } from 'date-fns';
import { Formik } from 'formik';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  documentTypeLabels,
  documentTypeOptions,
} from 'src/apis/document-exchange/types';
import { OperatorDocumentsInboxRequest } from 'src/apis/document-exchange/use-operator-documents-inbox';
import { OperatorDocumentsOutboxRequest } from 'src/apis/document-exchange/use-operator-documents-outbox';
import { Button } from 'src/components/buttons-and-actions/button';
import { ActiveFilters } from 'src/components/data-display/expandable-filters';
import { Divider } from 'src/components/dividers';
import { DateRangeInput } from 'src/components/form/datepicker';
import { ErrorHandler } from 'src/components/form/error-handler';
import {
  formGap,
  SimpleFormContainer,
} from 'src/components/form/form-container';
import { Input } from 'src/components/form/input';
import { MultiSelect } from 'src/components/form/select/multi-select';
import { Stack } from 'src/components/layout/stack';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import {
  documentStatusLabels,
  documentStatusOptions,
} from 'src/pages/shippers/documents/status';
import { PageParams } from 'src/pages/shippers/documents/use-page-params';

type FilterCardProps = {
  type: 'INBOX' | 'OUTBOX';
  pageParams: PageParams;
  documents: OperatorDocumentsOutboxRequest | OperatorDocumentsInboxRequest;
};

type TableFilter = PageParams['filterValues'];

const Content: FC<FilterCardProps> = ({ type, pageParams, documents }) => {
  const { filterLabels } = pageParams;
  const { minTablet, minDesktop } = useBreakpoints();
  const location = useLocation();

  return (
    <Formik<TableFilter>
      enableReinitialize
      initialValues={pageParams.filterValues}
      onSubmit={(values) => {
        values.changedAtStart = values.changedAtStart
          ? startOfSecond(new Date(values.changedAtStart)).toISOString()
          : values.changedAtEnd;

        values.changedAtEnd = values.changedAtEnd
          ? endOfMinute(
              endOfSecond(new Date(values.changedAtEnd))
            ).toISOString()
          : values.changedAtEnd;

        pageParams.setFilters(values);
      }}
    >
      <SimpleFormContainer mode="filter">
        <Stack
          gap={formGap}
          alignItems="baseline"
          templateColumns={
            minDesktop
              ? '1fr 1fr 1fr 1fr 1fr'
              : minTablet
                ? '1fr 1fr 1fr'
                : '1fr'
          }
        >
          <DateRangeInput
            groupLabel={filterLabels.changedAtStart}
            fromName="changedAtStart"
            fromLabel="Time of Modification (from)"
            toName="changedAtEnd"
            toLabel="Time of Modification (until)"
            time
          />
          <Input
            name="documentName"
            label={filterLabels.documentName}
            placeholder="Document Name"
          />
          <Input
            name="documentDescription"
            label={filterLabels.documentDescription}
            placeholder="Document's Description"
          />

          {type === 'OUTBOX' && (
            <MultiSelect
              name="documentStatus"
              label={filterLabels.documentStatus}
              options={documentStatusOptions}
              placeholder="E.g. Published"
            />
          )}

          <MultiSelect
            name="documentType"
            label={filterLabels.documentType}
            options={documentTypeOptions}
            placeholder="E.g. Business Communication"
          />
        </Stack>

        <Divider />

        <ErrorHandler serverError={documents.error} />

        <Stack flow="column" justifyContent="space-between">
          <div>
            {pageParams.hasActiveFilters && (
              <Button
                size={
                  location.pathname.startsWith('/operators')
                    ? 'small'
                    : 'default'
                }
                mode="secondary"
                onClick={pageParams.resetFilters}
              >
                Reset
              </Button>
            )}
          </div>

          <Button
            size={
              location.pathname.startsWith('/operators') ? 'small' : 'default'
            }
            type="submit"
            data-testid="filter-submit-button"
          >
            Filter
          </Button>
        </Stack>
      </SimpleFormContainer>
    </Formik>
  );
};

export const FilterCard: FC<FilterCardProps> = ({
  type,
  pageParams,
  documents,
}) => {
  return (
    <ActiveFilters
      pageParams={pageParams}
      tags={{
        documentType: {
          type: 'list',
          mappedValue: pageParams.value.documentType.map(
            (type) => documentTypeLabels[type]
          ),
        },
        documentStatus: {
          type: 'list',
          mappedValue: pageParams.value.documentStatus.map(
            (status) => documentStatusLabels[status]
          ),
        },
        documentName: {
          type: 'value',
        },
        documentDescription: {
          type: 'value',
        },
        changedAtStart: {
          type: 'date-time',
          end: 'changedAtEnd',
        },
      }}
    >
      <Content type={type} pageParams={pageParams} documents={documents} />
    </ActiveFilters>
  );
};
