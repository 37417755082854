import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Decimal from 'decimal.js-light';
import { useFormikContext } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuctionConfig,
  AuctionDetail,
  AuctionOrder,
  AuctionShortTerm,
  ComfortBidConfig,
} from 'src/apis/monolith/types';
import {
  SubmitShortTermBidData,
  usePlaceShortTermBid,
} from 'src/apis/monolith/use-place-short-term-bid';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { EmptyValue } from 'src/components/data-display/empty-value';
import {
  exactFormat,
  FormatValue,
} from 'src/components/data-display/format-value';
import { Checkbox } from 'src/components/form/checkbox';
import { FieldLayout } from 'src/components/form/field-layout';
import { Form } from 'src/components/form/form';
import { Input } from 'src/components/form/input';
import { Radios } from 'src/components/form/radios';
import {
  customValidation,
  requiredOutput,
} from 'src/components/form/zod-utilities';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { useScrollManager } from 'src/components/scroll-management';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOnChange } from 'src/hooks/use-on-change';
import { Referrer } from 'src/hooks/use-referrer';
import { useToast } from 'src/hooks/use-toasts';
import { ContentAnimation } from 'src/pages/login/animations';
import { FakeAuctionForComfortBid } from 'src/pages/transport/auctions/comfort-bids/create/utils';
import { undiscountedPriceCalculation } from 'src/pages/transport/auctions/components/undiscounted-price-calculation';
import {
  BaseOrder,
  buildSharedSubmitData,
  getDefaultSharedFormValues,
  getPrefilledSharedFormValues,
  getSharedFieldsSchema,
  getShortTermFieldsMeta,
  SharedEditFields,
  SharedReviewFields,
} from 'src/pages/transport/auctions/details/shared-form-logic';
import { physicalUnitLabels } from 'src/pages/transport/secondary-tradings/trade/network-point-card';
import { z } from 'zod';

type Props = {
  auction: AuctionShortTerm;
  auctionConfig: AuctionConfig;
  orders?: AuctionOrder[];
  referrer: Referrer;
  orderId?: string;
  setSelectedUndiscountedTariff: Dispatch<SetStateAction<boolean | null>>;
};

export const ShortTermBiddingForm: FC<Props> = ({
  auction,
  auctionConfig,
  orders,
  referrer,
  orderId,
  setSelectedUndiscountedTariff,
}) => {
  const [reviewStep, setReviewStep] = useState(false);
  const { scrollTo } = useScrollManager();
  const notify = useToast();
  const navigate = useNavigate();
  const placeBid = usePlaceShortTermBid();

  useEffect(() => {
    if (!placeBid.response) return;

    notify({
      type: 'success',
      children: 'Your bid has been submitted.',
    });
    navigate(referrer.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeBid.response]);

  const order = getOrder(orderId, orders);

  return (
    <>
      <Heading id="bidding-form-title" mode="section">
        {reviewStep
          ? 'Confirm Your Bid'
          : orderId
            ? 'Change Your Bid'
            : 'Add Your Bid'}
      </Heading>

      <Card data-testid="short-term-bidding-form">
        <Form
          initialValues={
            order
              ? getPrefilledShortTermBidFormValues(auction, order)
              : getDefaultShortTermBidFormValues(auction)
          }
          schema={(values: ShortTermBiddingFormValues) =>
            getShortTermBidSchema({
              values,
              auction,
              auctionConfig,
              deferTermsAndConditions: false,
            })
          }
          onSubmit={(values) => {
            if (!reviewStep) {
              setReviewStep(true);
            } else {
              const data = buildShortTermBiddingSubmitData({
                values,
                auction,
                auctionConfig,
                order,
              });
              placeBid.execute({ auctionId: auction.id, data });
            }
          }}
          constraintViolation={null}
          cancelOrResetButton={
            reviewStep ? (
              <Button onClick={() => setReviewStep(false)} mode="secondary">
                {orderId ? 'Edit Bid Changes' : 'Edit Bid'}
              </Button>
            ) : null
          }
          submitButton={
            reviewStep ? (
              <Button key="review" disabled={placeBid.pending} type="submit">
                {placeBid.pending
                  ? 'Submitting...'
                  : orderId
                    ? 'Submit Bid Changes'
                    : 'Place Bid'}
              </Button>
            ) : (
              <Button key="edit" type="submit">
                {orderId ? 'Review Bid Changes' : 'Review Bid'}
              </Button>
            )
          }
          container={({ children }) => (
            <AnimatePresence
              exitBeforeEnter
              initial={false}
              onExitComplete={() => scrollTo('#bidding-form-title', 'smooth')}
            >
              <ContentAnimation key={reviewStep ? 'review' : 'edit'}>
                {children}
              </ContentAnimation>
            </AnimatePresence>
          )}
        >
          {({ values }) =>
            reviewStep ? (
              <ReviewView auction={auction} auctionConfig={auctionConfig} />
            ) : (
              <ShortTermBiddingFields
                auction={auction}
                auctionConfig={auctionConfig}
                setSelectedUndiscountedTariff={setSelectedUndiscountedTariff}
                values={values}
                fieldPrefix=""
                deferTermsAndConditions={false}
              />
            )
          }
        </Form>
      </Card>
    </>
  );
};

const ReviewView: FC<{
  auction: AuctionShortTerm;
  auctionConfig: AuctionConfig;
}> = ({ auction, auctionConfig }) => {
  const { values } = useFormikContext<ValidShortTermBiddingFormValues>();
  const meta = getShortTermFieldsMeta({
    values,
    auction,
    auctionConfig,
    deferTermsAndConditions: false,
  });

  const currency =
    values.priceCurrency === 'TSO_0'
      ? auction.auctionSurcharge[0]!.originalUnit.label
      : auction.auctionSurcharge[1]!.originalUnit.label;

  return (
    <DataList data-testid="review-bid-data">
      <p>Please review your input before placing the bid.</p>

      <Spacer />

      <p>
        <strong>Bid</strong>
      </p>

      <DataItems>
        <span>Minimum</span>
        <span>
          <FormatValue
            value={values.minQuantity!}
            label={physicalUnitLabels[auction.networkPoint.physicalUnit]}
          />
        </span>

        <span>Maximum</span>
        <span>
          <FormatValue
            value={values.maxQuantity!}
            label={physicalUnitLabels[auction.networkPoint.physicalUnit]}
          />
        </span>

        <span>Surcharge</span>
        <span>
          <FormatValue value={values.price!} label={currency} />
        </span>

        {auctionConfig.withinDayRolloverAllowed && (
          <>
            <span>Roll Over</span>
            {values.withinDayRollover ? <span>Enabled</span> : <EmptyValue />}
          </>
        )}

        {meta.undiscounted.visible && (
          <>
            <span>Undiscounted Tariff</span>
            {values.undiscounted ? (
              <span>Selected</span>
            ) : (
              <EmptyValue label="Not Selected" />
            )}
          </>
        )}
      </DataItems>

      <SharedReviewFields
        auction={auction}
        auctionConfig={auctionConfig}
        meta={meta}
        values={values}
        deferTermsAndConditions={false}
      />
    </DataList>
  );
};

export const ShortTermBiddingFields: FC<{
  auction: AuctionShortTerm | FakeAuctionForComfortBid;
  auctionConfig:
    | AuctionConfig
    | (ComfortBidConfig & { withinDayRolloverAllowed: boolean });
  setSelectedUndiscountedTariff: Dispatch<SetStateAction<boolean | null>>;
  values: ShortTermBiddingFormValues;
  fieldPrefix: string;
  deferTermsAndConditions: boolean;
}> = ({
  auction,
  auctionConfig,
  setSelectedUndiscountedTariff,
  values,
  fieldPrefix,
  deferTermsAndConditions,
}) => {
  const { setFieldValue } = useFormikContext();
  const { minTablet } = useBreakpoints();
  const meta = getShortTermFieldsMeta({
    values,
    auction,
    auctionConfig,
    deferTermsAndConditions,
  });

  const { withinDayRolloverAllowed } = auctionConfig;

  const currency =
    values.priceCurrency === 'TSO_0'
      ? auction.possibleUnits[0].label
      : auction.possibleUnits[1]!.label;

  useEffect(() => {
    setSelectedUndiscountedTariff(values.undiscounted);
  }, [values.undiscounted, setSelectedUndiscountedTariff]);

  // reset price whenever currency changes
  useOnChange(
    () => setFieldValue(`${fieldPrefix}price`, null, true),
    values.priceCurrency
  );

  function handleSuggestions(priceTick: number, currentValue: number | null) {
    if (
      typeof currentValue === 'number' &&
      currentValue >= 0 &&
      new Decimal(currentValue).mod(priceTick).toNumber() === 0
    )
      return [];

    const factor = Math.floor((currentValue || 0) / priceTick);
    const factors =
      factor <= 0 ? [0, 1, 2, 3] : [factor - 1, factor, factor + 1, factor + 2];

    return factors.map((factor) =>
      new Decimal(priceTick).mul(factor).toNumber()
    );
  }

  const matchingPriceTick =
    currency === auction.priceTick[0].originalUnit.label
      ? auction.priceTick[0]
      : auction.priceTick[1]!;

  const withinDayRollover = withinDayRolloverAllowed ? (
    <Checkbox
      name={`${fieldPrefix}withinDayRollover`}
      label="Roll Over"
      optionLabel="Enable roll over to Within-Day auction"
      info={
        <Stack gap={1}>
          <strong>
            Place the bid in the first within-day auction if no capacity was
            allocated to the bid during the day-ahead auction.
          </strong>

          <p>
            IMPORTANT NOTICE: The bid will only be placed if (i) the TSO(s)
            offer(s) capacity in the respective auction at the concerned network
            point and if (ii) there was no cancellation and/or interruption of
            the respective day-ahead auction. The starting price and the
            capacity of the within-day auction might differ from the one of the
            day-ahead auction.
          </p>
        </Stack>
      }
    />
  ) : undefined;

  return (
    <>
      <Heading mode="card">Bid</Heading>

      {meta.priceCurrency.visible && (
        <Radios
          name={`${fieldPrefix}priceCurrency`}
          label="Currency"
          options={[
            {
              value: 'TSO_0',
              label: auction.possibleUnits[0].label,
            },
            {
              value: 'TSO_1',
              label: auction.possibleUnits[1]!.label,
            },
          ]}
        />
      )}

      <Input
        name={`${fieldPrefix}minQuantity`}
        label="Minimum*"
        unit={physicalUnitLabels[auction.networkPoint.physicalUnit]}
        type="number"
        placeholder="E.g. 1"
      />

      <Input
        name={`${fieldPrefix}maxQuantity`}
        label="Maximum*"
        unit={physicalUnitLabels[auction.networkPoint.physicalUnit]}
        type="number"
        placeholder="E.g. 1"
      />

      <Input
        name={`${fieldPrefix}price`}
        label="Surcharge*"
        unit={currency}
        type="number"
        min={0}
        step={matchingPriceTick.originalValue.value}
        placeholder={`E.g. ${matchingPriceTick.originalValue.value}`}
        hint={`The minimum price step is ${Number(
          matchingPriceTick.originalValue.value
        ).toLocaleString('en-GB', exactFormat)} ${currency}.`}
        suggestions={handleSuggestions(
          Number(matchingPriceTick.originalValue.value),
          values.price
        )}
        renderSuggestions={(props) => (
          <>
            <div style={{ padding: '1rem' }}>
              <small>Closest valid surcharges:</small>
            </div>
            {props.children}
          </>
        )}
      />

      {meta.undiscounted.visible && (
        <FieldLayout stacked={!minTablet}>
          <span />
          <Checkbox
            label="Undiscounted Tariff"
            hideLabel
            name={`${fieldPrefix}undiscounted`}
            hint={
              values.undiscounted
                ? auction.startingPrice
                  ? `${undiscountedPriceCalculation(
                      values.priceCurrency === 'TSO_0' ? 0 : 1,
                      auction,
                      values.undiscounted
                    ).toString()} ${
                      auction.startingPrice[
                        values.priceCurrency === 'TSO_0' ? 0 : 1
                      ]!.unit.label
                    }`
                  : undefined
                : undefined
            }
            optionLabel={
              <>
                Undiscounted Tariff &nbsp;
                <Tooltip
                  dataTestId="undiscounted-short-term-tarrif"
                  content="By selecting the „undiscounted tariff“ option, you accept and confirm usage of a storage facility with access to more than one market area (or to the market area of a neighbouring country). In this case, your capacity is ineligible for any discount according to the BEATE regulation of the Federal Network Agency. For more information, please see the TSO price sheet."
                >
                  {(targetProps) => (
                    <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                  )}
                </Tooltip>
              </>
            }
          />
        </FieldLayout>
      )}

      <SharedEditFields
        auction={auction}
        auctionConfig={auctionConfig}
        meta={meta}
        withinDayRollover={withinDayRollover}
        values={values}
        fieldPrefix={fieldPrefix}
      />
    </>
  );
};

type ValidateOptions = {
  values: {
    upgradeSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1' | 'BID_BOTH';
    conversionSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1';
    priceCurrency: 'TSO_0' | 'TSO_1';
    minQuantity: number | null;
    maxQuantity: number | null;
  };
  auction: AuctionShortTerm | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  deferTermsAndConditions: boolean;
};

export function getShortTermBidSchema({
  values,
  auction,
  auctionConfig,
  deferTermsAndConditions,
}: ValidateOptions) {
  const currency =
    values.priceCurrency === 'TSO_0'
      ? auction.possibleUnits[0].label
      : auction.possibleUnits[1]!.label;
  const matchingPriceTick =
    currency === auction.priceTick[0].originalUnit.label
      ? auction.priceTick[0]
      : auction.priceTick[1]!;

  const meta = getShortTermFieldsMeta({
    values,
    auction,
    auctionConfig,
    deferTermsAndConditions,
  });

  const maxQuantity = values.maxQuantity;

  const schema = getSharedFieldsSchema({
    auction,
    meta,
    values,
    maxQuantity,
  }).extend({
    minQuantity: z
      .number()
      .int('{label} cannot contain decimals.')
      .min(
        1,
        `{label} must be greater than or equal to 1 ${physicalUnitLabels[auction.networkPoint.physicalUnit]}.`
      )
      .nullable()
      .transform(requiredOutput()),
    maxQuantity: z
      .number()
      .int('{label} cannot contain decimals.')
      .min(
        1,
        `{label} must be greater than or equal to 1 ${physicalUnitLabels[auction.networkPoint.physicalUnit]}.`
      )
      .nullable()
      .transform(requiredOutput())
      .refine(
        (value) => {
          if (!values.minQuantity) return true;
          return value >= values.minQuantity;
        },
        `Your maximum can't be less than your minimum of ${values.minQuantity?.toLocaleString(
          'en-GB',
          exactFormat
        )} ${physicalUnitLabels[auction.networkPoint.physicalUnit]}.`
      )
      .refine(
        (value) =>
          auction.marketable
            ? value <= Number(auction.marketable.originalValue.value)
            : true,
        auction.marketable
          ? `Your maximum can't be more than the marketable amount of ${Number(
              auction.marketable.originalValue.value
            ).toLocaleString('en-GB', exactFormat)} ${
              physicalUnitLabels[auction.networkPoint.physicalUnit]
            }.`
          : ''
      ),
    price: z
      .number()
      .min(0, '{label} must be greater than or equal to 0.')
      .nullable()
      .transform(requiredOutput())
      .transform(
        customValidation(
          (value) =>
            new Decimal(value)
              .modulo(matchingPriceTick.originalValue.value)
              .equals(0),
          '{label} does not match price step.'
        )
      ),
    withinDayRollover: z.boolean(),
  });

  return schema;
}

export type ShortTermBiddingFormValues = z.input<
  ReturnType<typeof getShortTermBidSchema>
>;
export type ValidShortTermBiddingFormValues = z.output<
  ReturnType<typeof getShortTermBidSchema>
>;

export function buildShortTermBiddingSubmitData({
  values,
  auction,
  auctionConfig,
  order,
}: {
  values: ValidShortTermBiddingFormValues;
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  order?: AuctionOrder;
}): SubmitShortTermBidData {
  return {
    originOrderId: order ? order.id : null,
    minQuantity: {
      originalValue: {
        value: values.minQuantity!.toString(),
        floatingPointNumber: true,
      },
      originalUnit: physicalUnitLabels[auction.networkPoint.physicalUnit],
    },
    maxQuantity: {
      originalValue: {
        value: values.maxQuantity!.toString(),
        floatingPointNumber: true,
      },
      originalUnit: physicalUnitLabels[auction.networkPoint.physicalUnit],
    },
    price: {
      originalValue: {
        value: values.price!.toString(),
        floatingPointNumber: true,
      },
      originalUnit:
        values.priceCurrency === 'TSO_0'
          ? auction.possibleUnits[0]
          : auction.possibleUnits[1]!,
    },
    withinDayRollover: values.withinDayRollover,
    ...buildSharedSubmitData({ auction, auctionConfig, values }),
  };
}

export function getDefaultShortTermBidFormValues(
  auction: AuctionDetail | FakeAuctionForComfortBid
): ShortTermBiddingFormValues {
  return {
    minQuantity: null,
    maxQuantity: null,
    price: null,
    withinDayRollover: false,
    ...getDefaultSharedFormValues(auction),
  };
}

export function getPrefilledShortTermBidFormValues(
  auction: AuctionDetail | FakeAuctionForComfortBid,
  order: BaseOrder
): ShortTermBiddingFormValues {
  return {
    priceCurrency:
      // check if the currency of the placed bid matches the currency of TSO_1
      auction.possibleUnits[1] &&
      order.price?.originalUnit.label === auction.possibleUnits[1].label
        ? 'TSO_1'
        : 'TSO_0',
    minQuantity: order.minQuantity
      ? Number(order.minQuantity.originalValue.value)
      : null,
    maxQuantity: order.maxQuantity
      ? Number(order.maxQuantity.originalValue.value)
      : null,
    price: order.price ? Number(order.price.originalValue.value) : null,
    withinDayRollover: order.withinDayRollover ?? false,
    ...getPrefilledSharedFormValues(auction, order),
  };
}

export function getOrder(
  orderId: string | undefined,
  orders: AuctionOrder[] | undefined
) {
  return orderId && orders
    ? orders.find((order) => String(order.id) === orderId)
    : undefined;
}
