import { FC, useMemo } from 'react';
import { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import { ContactsParams } from 'src/apis/organisation-service/types';
import {
  ContactsRequest,
  useContacts,
} from 'src/apis/organisation-service/use-contacts';
import { Card } from 'src/components/data-display/card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Divider } from 'src/components/dividers';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Pagination } from 'src/components/navigation/pagination';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { usePageParams } from 'src/pages/market-information/players/operators/use-page-params';
import { PostalAddressAndWebsite } from 'src/pages/settings/assignment-settings/assignment-contact-point/page';
import { ContactType } from 'src/pages/settings/organisation/contacts/contact-type';
import { PhoneLink } from 'src/pages/settings/organisation/contacts/phone-link';

export const OperatorContactDetails: FC<{
  assignmentInfo: Successful<AssignmentInfoRequest>;
}> = ({ assignmentInfo }) => {
  const { data } = assignmentInfo.response;
  const contactsUrl = data._links.getAssignmentContacts?.href;

  return (
    <Stack gap={0.5}>
      <Divider />
      {contactsUrl && <AssignmentContacts url={contactsUrl} />}
      <strong>Postal Address for Documents</strong>
      <PostalAddressAndWebsite postalAddressContact={data} tooltipShown />
    </Stack>
  );
};

const AssignmentContacts: FC<{ url: string }> = ({ url }) => {
  const pageParams = usePageParams();
  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const contacts = useContacts({
    url,
    params,
  });

  if (!contacts.response) {
    return <PageSpinner />;
  }
  return (
    <>
      <strong>Assignment Contacts</strong>
      <Contacts contacts={contacts} />
      <Spacer />
    </>
  );
};

const Contacts: FC<{ contacts: Successful<ContactsRequest> }> = ({
  contacts,
}) => {
  const { minDesktop } = useBreakpoints();
  const contactItems = contacts.response.data._embedded?.items || [];
  return (
    <Stack gap={1}>
      {contactItems.length ? (
        <Stack>
          {contactItems.map((contact) => (
            <Card key={contact.contactId}>
              <Stack flow="column" justifyContent="space-between">
                <p>
                  <ExternalLink
                    mode="default-underlined"
                    href={`mailto:${contact.email}`}
                  >
                    {contact.email}
                  </ExternalLink>
                </p>
              </Stack>

              <Stack templateColumns={minDesktop ? '2fr 1fr' : '1fr'}>
                <div>
                  {contact.firstName || contact.lastName || contact.section ? (
                    <strong>
                      {contact.gender === 'F' && 'Mrs/Ms '}
                      {contact.gender === 'M' && 'Mr '}
                      {contact.firstName} {contact.lastName}
                      {(contact.firstName || contact.lastName) &&
                      contact.section
                        ? ', '
                        : null}
                      {contact.section}
                    </strong>
                  ) : null}
                  <ContactType contact={contact} />
                </div>

                {contact.phone || contact.backupPhone || contact.mobile ? (
                  <div>
                    {contact.phone && (
                      <p>
                        Phone: <PhoneLink value={contact.phone} />
                      </p>
                    )}

                    {contact.backupPhone && (
                      <p>
                        Backup Phone: <PhoneLink value={contact.backupPhone} />
                      </p>
                    )}

                    {contact.mobile && (
                      <p>
                        Mobile: <PhoneLink value={contact.mobile} />
                      </p>
                    )}
                  </div>
                ) : null}
              </Stack>
            </Card>
          ))}
          <Pagination {...contacts.response.data} configurable={false} />
        </Stack>
      ) : (
        <Card data-testid="no-contacts-defined-card">
          <EmptyValue label="There are no contacts defined." />
        </Card>
      )}
    </Stack>
  );
};
