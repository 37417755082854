import { FC, lazy } from 'react';
import { useDirectFcfsFlags } from 'src/hooks/use-direct-fcfs-flags';
import { TsoUserProvider } from 'src/pages/direct-fcfs/use-tso-user-context';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const DirectFcfs: FC = () => {
  const directFcfsFlags = useDirectFcfsFlags();
  if (
    !directFcfsFlags.data?._links?.['view-requests'] &&
    !directFcfsFlags.data?._links?.['read-product']
  )
    throw directFcfsFlags.error || new Forbidden();

  return (
    <TsoUserProvider
      value={{ organisationId: directFcfsFlags.data.organisationId }}
    >
      <Page directFcfsFlags={directFcfsFlags} />
    </TsoUserProvider>
  );
};
