import {
  AssignedUsersSortBy,
  OrganisationAssignmentStatus,
  UserAssignmentStatus,
  UserRole,
  UserStatus,
} from 'src/apis/assignment-service/types';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { z } from 'zod';

const offersSortyByValues = [
  'NAME',
  'EMAIL',
  'SHIPPER_NAME',
  'SHIPPER_EIC',
  'ROLE',
  'USER_ASSIGNMENT_STATUS',
  'ORGANISATION_ASSIGNMENT_STATUS',
  'TRADING_MARKET',
] as const satisfies readonly AssignedUsersSortBy[];

export const userRoleValues = [
  'USER',
  'ADMIN',
] as const satisfies readonly UserRole[];

export const userRoleValuesLabels: Record<UserRole, string> = {
  USER: 'User',
  ADMIN: 'Admin',
};

export const userRoleValuesOptions = labelsToOptions(userRoleValuesLabels);

export const userAssignmentStatusValues = [
  'WAITING_FOR_APPROVAL',
  'APPROVED',
  'TEMPORARILY_DISABLED',
  'CANCELLED',
  'NOT_REQUESTED',
] as const satisfies readonly UserAssignmentStatus[];

export const userAssignmentStatusLabels: Record<UserAssignmentStatus, string> =
  {
    WAITING_FOR_APPROVAL: 'Waiting for Approval',
    APPROVED: 'Approved',
    TEMPORARILY_DISABLED: 'Temporarily Disabled',
    CANCELLED: 'Cancelled',
    NOT_REQUESTED: 'Not Requested',
  };

export const userAssignmentStatusOptions = labelsToOptions(
  userAssignmentStatusLabels
);

export const organisationAssignmentStatusValues = [
  'WAITING_FOR_APPROVAL',
  'APPROVED',
  'TEMPORARILY_DISABLED',
  'CANCELLED',
] as const satisfies readonly OrganisationAssignmentStatus[];

export const organisationAssignmentStatusLabels: Record<
  OrganisationAssignmentStatus,
  string
> = {
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  APPROVED: 'Approved',
  TEMPORARILY_DISABLED: 'Temporarily Disabled',
  CANCELLED: 'Cancelled',
};

export const organisationAssignmentStatusOptions = labelsToOptions(
  organisationAssignmentStatusLabels
);

export const userStatusValues = [
  'ACTIVE',
  'DEACTIVATED',
  'DELETED',
] as const satisfies readonly UserStatus[];

export const userStatusLabels: Record<UserStatus, string> = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  DELETED: 'Deleted',
};

export const userStatusOptions = labelsToOptions(userStatusLabels);

export function getAssignedUsersPageParamsSchema() {
  return z
    .object({
      sortBy: z.enum(offersSortyByValues).default('NAME'),
      sortDirection: z.enum(['asc', 'desc']).default('desc'),
      name: z.string().nullable().default(null),
      email: z.string().nullable().default(null),
      shipperName: z.string().nullable().default(null),
      shipperEic: z.string().nullable().default(null),
      role: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(userRoleValues)).default([])
      ),
      userAssignmentStatus: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(userAssignmentStatusValues)).default([])
      ),
      organisationAssignmentStatus: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(organisationAssignmentStatusValues)).default([])
      ),
      userStatus: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(userStatusValues)).default([])
      ),
      tradingMarket: z.string().nullable().default(null),
    })
    .merge(getPaginationSchema());
}
