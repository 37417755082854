import { assignmentStateValues } from 'src/apis/monolith/mappings';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { z } from 'zod';

export function getShipperOverviewPageParamsForShipperListSchema() {
  return z
    .object({
      sortColumn: z.string().default('shipperName'),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
      shipperName: z.preprocess(
        preprocessAsArray,
        z.array(z.string()).default([])
      ),
      eic: z.string().nullable().default(null),
      state: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(assignmentStateValues)).default([])
      ),
      primaryAccess: z.string().nullable().default(null),
      secondaryAccess: z.string().nullable().default(null),
    })
    .merge(getPaginationSchema());
}
