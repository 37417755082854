import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getShipperOverviewPageParamsForShipperListSchema } from 'src/pages/shippers/organisations/schema';

// eslint-disable-next-line no-restricted-syntax
export enum ShippersOverviewSortColumn {
  SHIPPER_NAME = 'shipperName',
  EIC = 'eic',
  STATE = 'state',
  PRIMARY_ACCESS = 'primaryAccess',
  SECONDARY_ACCESS = 'secondaryAccess',
}

export function useShipperOverviewPageParams() {
  return usePageParamsFromSchema(
    getShipperOverviewPageParamsForShipperListSchema(),
    {
      filters: {
        shipperName: { label: 'Shipper' },
        eic: { label: 'EIC' },
        state: { label: 'Organisation Assignment' },
        primaryAccess: { label: 'Primary Access' },
        secondaryAccess: { label: 'Secondary Access' },
      },
    }
  );
}

export type ShipperOverviewPageParams = ReturnType<
  typeof useShipperOverviewPageParams
>;
