import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, lazy } from 'react';
import { AssignmentSettings } from 'src/apis/assignment-service/types';
import { AssignmentSettingsRequest } from 'src/apis/assignment-service/use-assignment-settings';
import { ContactsRequest } from 'src/apis/organisation-service/use-contacts';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { PageParams } from 'src/pages/settings/organisation/contacts/use-page-params';
import { Country } from 'src/pages/settings/registration/components/country';

const Contacts = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/assignment-contact-point/contacts'
    )
);

type Props = {
  assignmentSettings: Successful<AssignmentSettingsRequest>;
  contacts: Successful<ContactsRequest>;
  pageParams: PageParams;
};

const AssignmentContactPoint: FC<Props> = ({
  assignmentSettings,
  contacts,
  pageParams,
}) => {
  const { data } = assignmentSettings.response;
  return (
    <Card data-testid="contact-card-point">
      <Stack gap={0.5}>
        <Heading mode="card" data-testid="shipper-assignment-contact">
          Shipper Assignment Contacts
        </Heading>
        <strong>Contacts</strong>
        <Contacts contacts={contacts} pageParams={pageParams} />
        <Spacer />
        <Stack flow="column" alignItems="start" autoColumns="1fr auto">
          <strong>Postal Address for Documents</strong>
          {data._links.edit && (
            <Link
              to="/settings/assignment-settings/edit"
              mode="button-secondary"
              size="small"
              data-testid="contact-point-edit"
            >
              Edit
            </Link>
          )}
        </Stack>
        <PostalAddressAndWebsite postalAddressContact={data} />
      </Stack>
    </Card>
  );
};

export const PostalAddressAndWebsite: FC<{
  postalAddressContact: AssignmentSettings;
  tooltipShown?: boolean;
}> = ({ postalAddressContact, tooltipShown }) => {
  const { postalAddress, websiteUrl } = postalAddressContact;

  return (
    <Stack>
      <DataList data-testid="data-list-postalAddress-website">
        <DataItems>
          {tooltipShown ? (
            <Tooltip content="Please send the required documents to that address if needed">
              {() => (
                <>
                  <span>Postal Address</span>{' '}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </>
              )}
            </Tooltip>
          ) : (
            <span>Postal Address</span>
          )}

          {postalAddress ? (
            <div data-testid="postal-address-information">
              <p data-testid="postal-address-companyName">
                {postalAddress.companyName}
              </p>
              <p data-testid="postal-address-addressLine1">
                {postalAddress.addressLine1}
              </p>
              {postalAddress.addressLine2 && (
                <p data-testid="postal-address-addressLine2">
                  {postalAddress.addressLine2}
                </p>
              )}
              <p data-testid="postal-address-postCode-city">{`${postalAddress.postCode} ${postalAddress.city}`}</p>
              <p data-testid="postal-address-country">
                <Country code={postalAddress.country} />
              </p>
            </div>
          ) : (
            <EmptyValue label="There is no address defined" />
          )}

          <span>
            Registration URL
            {tooltipShown && (
              <Tooltip content="Here you can find more information about the assignments with that operator">
                {() => (
                  <>
                    {' '}
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </>
                )}
              </Tooltip>
            )}
          </span>

          {websiteUrl ? (
            <ExternalLink
              href={websiteUrl}
              data-testid="postal-address-website"
            >
              {websiteUrl}
            </ExternalLink>
          ) : (
            <EmptyValue label="None" />
          )}
        </DataItems>
      </DataList>
    </Stack>
  );
};

export default AssignmentContactPoint;
