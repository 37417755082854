import { ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

export function usePortal() {
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);

  const target = useMemo(() => <div ref={setPortalRef} />, []);

  const renderContent = useCallback(
    (children: ReactNode) => {
      if (!portalRef) return;
      return createPortal(children, portalRef);
    },
    [portalRef]
  );

  return { target, renderContent };
}

export type UsePortal = ReturnType<typeof usePortal>;
