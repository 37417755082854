import { useEffect } from 'react';
import { TsoRegistrationContact } from 'src/apis/shipper-registration/types';
import { ShipperRegistrationPublicRequest } from 'src/apis/shipper-registration/use-shipper-registration';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Options = {
  tsoId: string;
  shipperRegistrationPublic: ShipperRegistrationPublicRequest;
};

export function useTsoRegistrationContact({
  tsoId,
  shipperRegistrationPublic,
}: Options) {
  const registrationData = useAxios(
    (axios, baseConfig, { url, tsoId }: { url: string; tsoId: string }) =>
      axios.request<TsoRegistrationContact>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ tsoId }),
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // 404 in case no registration contact was created, yet
        if (isServerError(error, 404)) return error;
        throw error;
      },
    }
  );

  useEffect(() => {
    if (!shipperRegistrationPublic.response) return;
    const url =
      shipperRegistrationPublic.response.data._links.tsoRegistrationContact
        .href;

    registrationData.execute({ tsoId, url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipperRegistrationPublic.response?.data._links.tsoRegistrationContact,
    tsoId,
  ]);

  return registrationData;
}

export type TsoRegistrationContactRequest = ReturnType<
  typeof useTsoRegistrationContact
>;
