import { FC, lazy } from 'react';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError } from 'src/hooks/use-axios';
import { useDocumentExchangeRequest } from 'src/pages/shippers/upload-documents/use-document-exchange';

const Page = lazy(() => import('./page'));

export const ShippersDocumentsOverview: FC = () => {
  const documentExchange = useDocumentExchangeRequest();

  if (!documentExchange.response && !isNetworkError(documentExchange.error)) {
    return <PageSpinner />;
  }

  if (
    !documentExchange.response?.data._links?.getOperatorDocumentsOutbox?.href
  ) {
    return <ThrowNotFound />;
  }

  const areLinksAvailableToUploadDocument = !!(
    documentExchange.response?.data._links &&
    documentExchange.response?.data._links.shippers &&
    documentExchange.response?.data._links.uploadFile
  );

  return (
    <Page
      operatorDocumentsOutboxLink={
        documentExchange.response?.data._links?.getOperatorDocumentsOutbox.href
      }
      allowAddNewDocument={areLinksAvailableToUploadDocument}
    />
  );
};
