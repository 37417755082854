import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { GroupWrap } from 'src/components/group-wrap';
import { ActiveSection } from 'src/components/layout/header/types';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useSystemInfo } from 'src/hooks/use-system-info';

const angularRoutes = [
  '#/auctions',
  '#/secondary-trading',
  '#/secondary-overview',
  '#/network-point',
  '#/create-proposal',
  '#/edit-proposal',
  '#/create-external-trade',
];

export const isTransportActive = (pathname: string, hash: string) =>
  pathname.startsWith('/transport') ||
  pathname.startsWith('/snam-bidding') ||
  (pathname.startsWith('/platform') &&
    angularRoutes.some((angularRoute) => hash.startsWith(angularRoute)));

export const SecondaryNavTransport: FC<{
  activeSection: ActiveSection;
}> = ({ activeSection }) => {
  const { pathname, hash } = useLocation();
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const { minTablet } = useBreakpoints();
  const systemInfo = useSystemInfo();

  if (!activeSection.transport) return null;
  if (monolithUser?.isSso) return null;

  return (
    <GroupWrap
      gap={2}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      <Link
        mode="secondary"
        to="/transport/auctions"
        isActive={
          (pathname.startsWith('/platform') && hash.startsWith('#/auctions')) ||
          pathname.startsWith('/transport/auctions') ||
          pathname.startsWith('/snam-bidding')
        }
      >
        Auctions
      </Link>

      {monolithUser?.isTso ? (
        <>
          <Link mode="secondary" to="/transport/fcfs-booking">
            FCFS Booking
          </Link>

          <Link
            mode="secondary"
            to="/transport/secondary"
            isActive={
              (pathname.startsWith('/platform') &&
                (hash.startsWith('#/create-external-trade') ||
                  hash.startsWith('#/edit-proposal') ||
                  hash.startsWith('#/create-proposal'))) ||
              pathname.startsWith('/transport/secondary')
            }
          >
            Secondary Trading
          </Link>

          <Link
            mode="secondary"
            to="/transport/network-points"
            isActive={
              (pathname.startsWith('/platform') &&
                hash.startsWith('#/network-point') &&
                !hash.startsWith('#/network-point/bundle')) ||
              pathname.startsWith('/transport/network-points')
            }
          >
            Network Points
          </Link>

          <Link
            mode="secondary"
            to="/transport/bundle-requests"
            isActive={
              (pathname.startsWith('/platform') &&
                hash.startsWith('#/network-point/bundle')) ||
              pathname.startsWith('/transport/bundle-requests')
            }
          >
            Bundle Requests
          </Link>
        </>
      ) : (
        <>
          <Link mode="secondary" to="/transport/fcfs-booking">
            FCFS Booking
          </Link>

          {systemInfo.featureMultiYearCapacity && (
            <ExternalLink
              mode="secondary"
              href={`${PRISMA_CONFIG.angularUrl}/#/multi-year-auctions`}
            >
              Multi-Year Capacity
            </ExternalLink>
          )}

          <Link
            mode="secondary"
            to="/transport/secondary"
            isActive={
              (pathname.startsWith('/platform') &&
                (hash.startsWith('#/create-external-trade') ||
                  hash.startsWith('#/edit-proposal') ||
                  hash.startsWith('#/create-proposal'))) ||
              pathname.startsWith('/transport/secondary')
            }
          >
            Secondary Trading
          </Link>

          <Link
            mode="secondary"
            to="/transport/network-points"
            isActive={
              (pathname.startsWith('/platform') &&
                hash.startsWith('#/network-point')) ||
              pathname.startsWith('/transport/network-points')
            }
          >
            Network Points
          </Link>
        </>
      )}
    </GroupWrap>
  );
};
