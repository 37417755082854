import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getAssignedUsersPageParamsSchema } from 'src/pages/shippers/users/schema';

export function useAssignedUsersPageParams() {
  return usePageParamsFromSchema(getAssignedUsersPageParamsSchema(), {
    filters: {
      name: { label: 'Name' },
      email: { label: 'Email' },
      shipperName: { label: 'Shipper Name' },
      shipperEic: { label: 'Shipper EIC' },
      role: { label: 'Role' },
      userAssignmentStatus: { label: 'User Assignment Status' },
      organisationAssignmentStatus: { label: 'Organisation Assignment Status' },
      userStatus: { label: 'User Status' },
      tradingMarket: { label: 'Trading Market' },
    },
  });
}

export type AssignedUsersPageParams = ReturnType<
  typeof useAssignedUsersPageParams
>;
