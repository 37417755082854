import { FC, useLayoutEffect } from 'react';
import { ReactShellApi } from 'src/components/layout/react-shell-type';
import { DownloadToast } from 'src/components/monolith-download';
import { useAxiosInstance } from 'src/hooks/use-axios';
import { useCognito } from 'src/hooks/use-cognito';
import { useToast } from 'src/hooks/use-toasts';
import { chooseAuthenticationToken } from 'src/utils/access-token-config';
import { baseUrl } from 'src/utils/base-url';
import { v4 as uuid } from 'uuid';

/**
 * Our "dirty" helper to share React functionality in an easy way with Angular.
 */
export const ReactShell: FC = () => {
  const notify = useToast();
  const axios = useAxiosInstance();
  const cognito = useCognito();

  useLayoutEffect(() => {
    // expose react shell api to angular
    const reactShell: ReactShellApi = {
      downloadFile(href: string) {
        const id = uuid();
        notify({
          id,
          type: 'success',
          children: <DownloadToast id={id} href={href} />,
          timeout: false,
        });
      },
      axios,
      chooseAuthenticationToken,
      cognito,
    };
    (window as any).reactShell = reactShell;

    // create angular iframe *after* react shell api is exposed
    const container = document.getElementById('angular-container');

    // this hook runs twice in React 18 dev mode.
    // if we find children, we already created the iframe.
    if (container?.children.length) return;

    const iframe = document.createElement('iframe');
    iframe.src = `${baseUrl}angular#initialize`;
    iframe.name = 'embedded-angular';
    iframe.style.display = 'none';
    iframe.style.width = '100%';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.style.position = 'absolute';
    container?.appendChild(iframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
