import { memo } from 'react';
import { AssignmentState } from 'src/apis/monolith/types';
import { MetaLabel } from 'src/components/data-display/meta-label';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { MarketType } from 'src/pages/operators/assignments/overview/types';
import { Colors } from 'src/styles';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const assignmentStateLabels: Record<AssignmentState, string> = {
  APPROVED: 'Approved',
  NOT_APPROVED: 'Waiting for Approval',
  CANCELED: 'Cancelled',
  PENDING: 'Temporarily Disabled',
};

export const assignmentStateTag = (value: string) => {
  switch (value) {
    case 'APPROVED':
    case 'NOT_APPROVED':
    case 'CANCELED':
    case 'PENDING':
      return assignmentStateLabels[value];
  }
};

export const assignmentStateOptions = labelsToOptions(assignmentStateLabels);

type Props = {
  value: AssignmentState;
  marketAccess?: MarketType;
  'data-testid'?: string;
};

export const colorMap: Record<AssignmentState, string> = {
  APPROVED: Colors.success,
  NOT_APPROVED: Colors.warning,
  CANCELED: Colors.error,
  PENDING: Colors.brandLight2,
};

export const AssignmentStatusDisplayMonolith = memo<Props>(
  ({ value, marketAccess, 'data-testid': testId }) => {
    const metaLabels = marketAccess?.map((market) => {
      switch (market) {
        case 'PRIMARY':
          return (
            <MetaLabel key={market} color="primary">
              Primary Market
            </MetaLabel>
          );
        case 'SECONDARY':
          return (
            <MetaLabel key={market} color="secondary">
              Secondary Market
            </MetaLabel>
          );
      }
    });

    return (
      <StatusDisplay
        status={assignmentStateLabels[value]}
        iconColor={colorMap[value]}
        metaLabels={<>{metaLabels}</>}
        data-testid={testId}
      />
    );
  }
);
