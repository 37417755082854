import {
  faMinusCircle,
  faPlusCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, FormikContextType, useFormikContext } from 'formik';
import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import {
  DirectFcfsNotificationSettingsItem,
  DirectFcfsNotificationSettingsType,
} from 'src/apis/direct-fcfs/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { Checkbox } from 'src/components/form/checkbox';
import { Form } from 'src/components/form/form';
import { Input } from 'src/components/form/input';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { PageSpinner } from 'src/components/spinner-container';
import { Heading } from 'src/components/text/heading';
import { useToast } from 'src/hooks/use-toasts';
import {
  useNotificationSettings,
  useOrganizationApiNotificationConfig,
} from 'src/pages/settings/direct-fcfs/use-notification-settings';
import {
  useNotificationSettingsUpdate,
  useOrganizationApiNotificationConfigUpdate,
} from 'src/pages/settings/direct-fcfs/use-notification-settings-update';
import { z } from 'zod';

const notificationSettingsTypeLabels: Record<
  DirectFcfsNotificationSettingsType,
  string
> = {
  TSO_COMMUNICATION: 'TSO Communication',
};

const notificationSettingsTypeTooltips: Record<
  DirectFcfsNotificationSettingsType,
  string
> = {
  TSO_COMMUNICATION:
    'Confirmation (+Excel file) or unsuccessful participation to TSO',
};

const cleanupEmailList = (list: (string | null)[]) =>
  list.filter((i) => !!i) as string[];

const emailSchema = z.string().email('Invalid email address.').nullable();

const settingsItemSchema = z
  .object({
    type: z.enum(['TSO_COMMUNICATION']),
    emailTo: z.array(emailSchema),
    emailCc: z.array(emailSchema),
  })
  .superRefine((item, ctx) => {
    const toEmails = cleanupEmailList(item.emailTo);
    if (toEmails.length > 0) return;
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['emailTo', 0],
      message: 'Please add at least one To email address.',
    });
  });

const settingsSchema = z.array(settingsItemSchema);

type SettingsFormValues = z.input<typeof settingsSchema>;
type ValidSettingsFormValues = z.output<typeof settingsSchema>;

const apiSettingsSchema = z.object({
  enabled: z.boolean(),
  endpoint: z
    .string()
    .url('Endpoint must be a valid URL.')
    .nullable()
    .transform(requiredOutput()),
  secondsBetweenAttempts: z
    .number()
    .min(0.1)
    .max(600, 'Interval between attempts has to be less than or equal 600.')
    .nullable()
    .transform(requiredOutput()),
  maxAttempts: z
    .number()
    .min(1)
    .max(10, 'Maximum attempts has to be less than or equal 10.')
    .nullable()
    .transform(requiredOutput()),
  authorization: z.object({
    headerName: z.string().nullable().transform(requiredOutput()),
    headerValue: z.string().nullable().transform(requiredOutput()),
  }),
  fallbackEmails: z.array(emailSchema),
});

type ApiSettingsFormValues = z.input<typeof apiSettingsSchema>;
type ValdApiSettingsFormValues = z.output<typeof apiSettingsSchema>;

export const EmailNotificationsForm: FC = () => {
  const notification = useToast();
  const settingsRequest = useNotificationSettings();
  const settingsUpdateRequest = useNotificationSettingsUpdate();

  const apiSettingsRequest = useOrganizationApiNotificationConfig();
  const apiSettingsUpdateRequest = useOrganizationApiNotificationConfigUpdate();

  const formRef = useRef<FormikContextType<
    DirectFcfsNotificationSettingsItem[]
  > | null>(null);
  const formRefApi = useRef<FormikContextType<ApiSettingsFormValues> | null>(
    null
  );

  useEffect(() => {
    if (!settingsUpdateRequest.response) return;
    notification({ type: 'success', children: 'Saved' });
    settingsRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsUpdateRequest.response]);

  useEffect(() => {
    if (!settingsRequest.response || !formRef.current) return;
    const settings = settingsRequest.response.data;
    const values = settings.map(({ type, emailTo, emailCc }) => {
      if (emailTo.length === 0) {
        emailTo = [''];
      }
      if (emailCc.length === 0) {
        emailCc = [''];
      }
      return { type, emailTo, emailCc };
    });

    formRef.current.resetForm({ values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsRequest.response]);

  useEffect(() => {
    if (!apiSettingsUpdateRequest.response) return;
    notification({ type: 'success', children: 'Saved' });
    apiSettingsRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSettingsUpdateRequest.response]);

  useEffect(() => {
    if (!apiSettingsRequest.response || !formRefApi.current) return;
    const {
      fallbackEmails,
      intervalBetweenAttemptsInMillis,
      ...restApiSettings
    } = apiSettingsRequest.response.data;
    const values = {
      ...restApiSettings,
      secondsBetweenAttempts: intervalBetweenAttemptsInMillis / 1000,
      fallbackEmails:
        !fallbackEmails || fallbackEmails.length === 0 ? [''] : fallbackEmails,
    };
    formRefApi.current.resetForm({ values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSettingsRequest.response]);

  // Add spinner
  if (!settingsRequest.response && !apiSettingsRequest.response)
    return <PageSpinner />;

  const onSubmit = (data: ValidSettingsFormValues) => {
    settingsUpdateRequest.execute(
      data.map((item) => ({
        type: item.type,
        emailTo: cleanupEmailList(item.emailTo),
        emailCc: cleanupEmailList(item.emailCc),
      }))
    );
  };

  const onSubmitApiSettings = (data: ValdApiSettingsFormValues) => {
    const payload = {
      ...data,
      fallbackEmails: cleanupEmailList(data.fallbackEmails),
      intervalBetweenAttemptsInMillis: data.secondsBetweenAttempts * 1000,
      secondsBetweenAttempts: undefined,
    };
    apiSettingsUpdateRequest.execute(payload);
  };

  return (
    <>
      <Card>
        <Form
          initialValues={[]}
          onSubmit={onSubmit}
          schema={settingsSchema}
          constraintViolation={null}
          cancelOrResetButton={
            <Button
              mode="secondary"
              data-testid="reset"
              onClick={() => settingsRequest.execute()}
            >
              Reset
            </Button>
          }
          submitButton={
            <Button type="submit" data-testid="save">
              Save
            </Button>
          }
        >
          {({ values }) => (
            <>
              {values.map((_, idx) => (
                <ItemForm key={idx} index={idx} />
              ))}
              <StoreFormContextRef
                // @ts-expect-error assignment should be correct
                keepRef={(formikContext) => (formRef.current = formikContext)}
              />
            </>
          )}
        </Form>
      </Card>

      <Heading mode="section">API Settings</Heading>
      <Card>
        <Form
          initialValues={{
            enabled: false,
            fallbackEmails: [],
            endpoint: null,
            authorization: { headerName: null, headerValue: null },
            secondsBetweenAttempts: null,
            maxAttempts: null,
          }}
          onSubmit={onSubmitApiSettings}
          schema={apiSettingsSchema}
          constraintViolation={null}
          cancelOrResetButton={
            <Button
              mode="secondary"
              data-testid="notification-settings-reset"
              onClick={() => apiSettingsRequest.execute()}
            >
              Reset
            </Button>
          }
          submitButton={
            <Button type="submit" data-testid="notification-settings-save">
              Save
            </Button>
          }
        >
          <StoreFormContextRef
            keepRef={(formikContext) => (formRefApi.current = formikContext)}
          />
          <ApiSettingsForm />
        </Form>
      </Card>
    </>
  );
};

const StoreFormContextRef: FC<{
  keepRef: (context: FormikContextType<any>) => void;
}> = ({ keepRef }) => {
  const formik = useFormikContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => void keepRef(formik), []);
  return null;
};

const ItemForm: FC<{
  index: number;
}> = ({ index }) => {
  const { values } = useFormikContext<DirectFcfsNotificationSettingsItem[]>();
  const type = values[index].type;
  return (
    <Card>
      <Stack gap={2}>
        <Heading mode="card">
          {notificationSettingsTypeLabels[type]}{' '}
          <Tooltip
            content={
              <Stack gap={1}>
                <p>{notificationSettingsTypeTooltips[type]}</p>
              </Stack>
            }
          >
            {(targetProps) => (
              <FontAwesomeIcon icon={faQuestionCircle} aria-hidden />
            )}
          </Tooltip>
        </Heading>
        <div>
          <EmailArrayForm index={index} field="emailTo" required label="To" />
        </div>
        <EmailArrayForm index={index} field="emailCc" label="CC" />
      </Stack>
    </Card>
  );
};

const EmailArrayForm: FC<{
  index: number;
  field: 'emailTo' | 'emailCc';
  label: string;
  required?: boolean;
}> = ({ index, field, label, required = false }) => {
  const { values } = useFormikContext<SettingsFormValues>();
  const fieldBaseName = `${index}.${field}`;
  const value = values[index][field] as string[];
  return (
    <EmailArrayFormComponent
      fieldBaseName={fieldBaseName}
      value={value}
      label={label}
      required={required}
    />
  );
};

const EmailArrayFormComponent: FC<{
  fieldBaseName: string;
  value: string[];
  label: string;
  required?: boolean;
}> = ({ fieldBaseName, value, label, required = false }) => {
  if (value.length === 0) value = [''];
  return (
    <FieldArray
      name={fieldBaseName}
      render={(arrayHelper) => (
        <Stack templateColumns="auto" gap={1}>
          {value.map((_, idx) => {
            const first = idx === 0;
            return (
              <Stack
                key={idx}
                flow="column"
                templateColumns="3fr 7fr"
                gap={0.5}
              >
                <strong>{first ? `${label}${required ? '*' : ''}` : ''}</strong>
                <Input
                  type="text"
                  name={`${fieldBaseName}[${idx}]`}
                  label={label}
                  hideLabel
                  addon={
                    <Button
                      mode="icon"
                      onClick={() => arrayHelper.remove(idx)}
                      disabled={idx === 0}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} aria-hidden />{' '}
                      Remove
                    </Button>
                  }
                />
              </Stack>
            );
          })}
          <Button
            mode="icon"
            onClick={() => arrayHelper.push('')}
            style={{ justifySelf: 'end' }}
          >
            <FontAwesomeIcon icon={faPlusCircle} aria-hidden /> Add Recipient
          </Button>
        </Stack>
      )}
    />
  );
};

const ApiSettingsForm: FC = () => {
  const { values } = useFormikContext<ApiSettingsFormValues>();
  const fallbackEmails: string[] = (values.fallbackEmails as string[]) ?? [];
  return (
    <Card>
      <Stack gap={2}>
        <Checkbox name="enabled" label="Enabled" optionLabel="" />
        <Input
          name="endpoint"
          label="Endpoint"
          placeholder="Endpoint URL"
          markAsRequired
        />
        <Input
          type="number"
          name="secondsBetweenAttempts"
          label="Interval between attempts"
          placeholder="E.g. 10"
          unit="seconds"
          markAsRequired
        />
        <Input
          type="number"
          name="maxAttempts"
          label="Maximum attempts"
          placeholder="E.g. 2"
          unit="times"
          markAsRequired
        />
        <Input
          name="authorization.headerName"
          label="Header name"
          placeholder="Header name"
          markAsRequired
        />
        <Input
          name="authorization.headerValue"
          type="password"
          autoComplete="new-password"
          label="Header value"
          placeholder="Header value"
          markAsRequired
        />
        <EmailArrayFormComponent
          fieldBaseName="fallbackEmails"
          value={fallbackEmails}
          label="Fallback Email To"
        />
      </Stack>
    </Card>
  );
};
