import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

type Props = {
  isOpen: boolean;
  showError?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
};

export const SelectToggleIcon: FC<Props> = ({
  isOpen,
  showError,
  disabled,
  'data-testid': dataTestId,
}) => {
  return (
    <Wrapper error={showError} disabled={disabled} data-testid={dataTestId}>
      <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  error?: boolean;
  disabled?: boolean;
}>`
  color: ${Colors.brand};
  pointer-events: initial;

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${Colors.inactive};
      background: ${Colors.inactiveLighter};
      cursor: not-allowed;
      color: ${Colors.inactive};
    `};
`;
