import { useNow } from 'src/hooks/use-now';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getGasMonth } from 'src/pages/gdm/utils';
import { getPageParamsSchema } from 'src/pages/reporting/balancing-group-allocations/schema';

export function usePageParams() {
  const now = useNow();
  const gasMonth = getGasMonth(now);
  return usePageParamsFromSchema(getPageParamsSchema(gasMonth), {
    filters: {
      creationDateStart: { label: 'Time of Creation' },
      creationDateEnd: { label: 'Time of Creation' },
      networkPointId: { label: 'Network Point' },
      balancingGroupId: { label: 'Balancing Group' },
      allocationIntervalIntersectionFilterStart: {
        label: 'Allocation Runtime',
      },
      allocationIntervalIntersectionFilterEnd: { label: 'Allocation Runtime' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;

export type PageParamsValue = PageParams['value'];
