import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useOptionalAssignmentService } from 'src/apis/assignment-service/use-optional-assignment-service';
import { useOptionalAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import { useOptionalLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import {
  OrganisationServiceRequest,
  useOptionalOrganisationService,
} from 'src/apis/organisation-service/use-organisation-service';
import { useRegularFcfsRoot } from 'src/apis/regular-fcfs/use-regular-fcfs-root';
import { HeaderPrimary } from 'src/components/layout/header/primary';
import { HeaderSecondary } from 'src/components/layout/header/secondary';
import { isAggregateEuActive } from 'src/components/layout/header/secondary-nav-aggregate-eu';
import { isDashboardActive } from 'src/components/layout/header/secondary-nav-dashboard';
import { isGdmActive } from 'src/components/layout/header/secondary-nav-gdm';
import { isLngActive } from 'src/components/layout/header/secondary-nav-lng';
import { isLngNewActive } from 'src/components/layout/header/secondary-nav-lng-new';
import { isReportingActive } from 'src/components/layout/header/secondary-nav-reporting';
import { isStorageActive } from 'src/components/layout/header/secondary-nav-storage';
import { isTransportActive } from 'src/components/layout/header/secondary-nav-transport';
import { useMobileNav } from 'src/components/layout/header/use-mobile-nav';
import { LayoutHeader } from 'src/components/layout/layout';
import { SimpleHeader } from 'src/components/layout/simple-page-layout';
import { AngularModalDimmer } from 'src/components/overlay/angular-modal-dimmer';
import { stickyHeaderId } from 'src/components/scroll-management';
import { Successful } from 'src/hooks/use-axios';
import { useDirectFcfsFlags } from 'src/hooks/use-direct-fcfs-flags';
import { useHeaderHeightMeasure } from 'src/hooks/use-header-height';
import { useIsSnamAdmin } from 'src/pages/snam-bid-collector/use-is-snam-admin';
import { Colors } from 'src/styles';
import styled from 'styled-components';

export const Header: FC = () => {
  const { pathname, hash } = useLocation();

  const authorizationService = useOptionalAuthorizationService();
  const organisationService = useOptionalOrganisationService();
  const capacityManagementRoot = useCapacityManagement();
  const regularFcfsService = useRegularFcfsRoot();
  const directFcfsService = useDirectFcfsFlags();
  const lngMarketingRoot = useOptionalLngMarketingRoot();
  const assignmentService = useOptionalAssignmentService();

  const newLngUsed = Boolean(
    lngMarketingRoot?.response?.data._links.getOffersPublic
  );

  const activeSection = useMemo(() => {
    const dashboard = isDashboardActive(pathname, hash);
    const transport = isTransportActive(pathname, hash);
    const storage = isStorageActive(pathname, hash);
    const lng = isLngActive(pathname, hash, newLngUsed);
    const lngNew = isLngNewActive(pathname, hash, newLngUsed);
    const reporting = isReportingActive(pathname, hash);
    const gdm = isGdmActive(pathname);
    const aggregateEu = isAggregateEuActive(pathname);

    return {
      dashboard,
      transport,
      storage,
      reporting,
      gdm,
      lng,
      lngNew,
      aggregateEu,
    };
  }, [pathname, hash, newLngUsed]);

  const ref = useHeaderHeightMeasure();
  const isSnamAdmin = useIsSnamAdmin();
  const mobileNav = useMobileNav();

  if (
    (authorizationService.response === null &&
      authorizationService.error === null) ||
    (lngMarketingRoot?.response === null && lngMarketingRoot.error === null) ||
    (organisationService.response === null &&
      organisationService.error === null) ||
    !regularFcfsService.loaded ||
    (assignmentService.response === null && assignmentService.error === null) ||
    isSnamAdmin === undefined
  )
    return (
      <LayoutHeader id={stickyHeaderId} ref={ref}>
        <SimpleHeader />
      </LayoutHeader>
    );

  return (
    <LayoutHeader
      id={stickyHeaderId}
      ref={ref}
      fullscreen={mobileNav.value !== null}
      onClick={(e) => {
        if (mobileNav.value === null) return;
        // when the mobile nav is open the LayoutHeader is covering the whole screen,
        // even if it is invisble. when the user directly clicks on the LayoutHeader (which means the
        // user actually wanted to click _outside_ the mobile nav) we close the mobile nav.
        if (e.target instanceof HTMLElement && e.target.id === stickyHeaderId)
          mobileNav.setValue(null);
      }}
    >
      <AngularModalDimmer>
        <header>
          <HeaderPrimary
            activeSection={activeSection}
            authorizationService={
              authorizationService.response ? authorizationService : undefined
            }
            newLngUsed={newLngUsed}
            organisationService={
              organisationService.response === null
                ? undefined
                : (organisationService as Successful<OrganisationServiceRequest>)
            }
            capacityManagementService={capacityManagementRoot}
            regularFcfsService={regularFcfsService.response?.data}
            directFcfsService={directFcfsService.data}
            assignmentService={
              assignmentService.response
                ? (assignmentService as Successful<AssignmentServiceRequest>)
                : undefined
            }
            mobileNav={mobileNav}
          />
          <HeaderSecondary
            isSnamAdmin={isSnamAdmin}
            activeSection={activeSection}
            mobileNav={mobileNav}
            newLngUsed={newLngUsed}
          />
        </header>
        <LoadingBar id="react-loading-bar" />
      </AngularModalDimmer>
    </LayoutHeader>
  );
};

// needed for angulars loading states
const LoadingBar = styled.div`
  position: fixed;
  height: 1px;
  background: ${Colors.brand};

  width: 0%;
  opacity: 1;
`;
