import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import { Sidebar } from 'src/components/layout/sidebar';
import { Link } from 'src/components/navigation/link';

export const OperatorsSidebar: FC = () => {
  const capacityManagement = useCapacityManagement();
  const { pathname } = useLocation();
  return (
    <Sidebar>
      <Link
        mode="sidebar"
        to="/operators/assignments"
        isActive={
          pathname.startsWith('/operators/assignments') ||
          pathname.startsWith('/dashboard/operators/assignments')
        }
      >
        Assignments
      </Link>

      <Link mode="sidebar" to="/operators/operator-documents">
        Documents
      </Link>

      {!!capacityManagement?._links?.myInvoicesV2 && (
        <Link mode="sidebar" to="/operators/invoices">
          Invoices
        </Link>
      )}
    </Sidebar>
  );
};
