import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import { GroupWrap } from 'src/components/group-wrap';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Successful } from 'src/hooks/use-axios';
import { UsePortal } from 'src/hooks/use-portal';
import { useDocumentExchange } from 'src/pages/shippers/upload-documents/use-document-exchange';
type Props = {
  actionPortal: UsePortal;
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const ShipperNavigationBar: FC<Props> = ({
  actionPortal,
  assignmentService,
}) => {
  const { pathname } = useLocation();
  const capacityManagement = useCapacityManagement();
  const documentExchange = useDocumentExchange();

  return (
    <Stack flow="column" gap={1} justifyContent="space-between">
      <GroupWrap
        gap={2}
        data-testid="secondary-nav"
        alignItems="center"
        flexDirection="row"
      >
        <Link
          mode="secondary"
          to="/shippers/organisations"
          isActive={pathname.includes('/shippers/organisations')}
          data-testid="secondary-nav-organisations"
        >
          Organisations
        </Link>

        {documentExchange?._links.getOperatorDocumentsOutbox?.href && (
          <Link
            to="/shippers/documents"
            isActive={pathname.includes('/shippers/documents')}
            mode="secondary"
          >
            Documents
          </Link>
        )}

        {!!capacityManagement?._links?.invoicesV2 && (
          <Link
            to="/shippers/invoices"
            isActive={pathname.includes('/shippers/invoices')}
            mode="secondary"
          >
            Invoices
          </Link>
        )}

        <Link
          to="/shippers/credit-limit"
          isActive={pathname.includes('/shippers/credit-limit')}
          mode="secondary"
        >
          Credit Limits
        </Link>
        {assignmentService.response.data._links.assignedUsers && (
          <Link
            to="/shippers/users"
            isActive={pathname.includes('/shippers/users')}
            mode="secondary"
          >
            Users
          </Link>
        )}
      </GroupWrap>

      {actionPortal.target}
    </Stack>
  );
};
