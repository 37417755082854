import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { Links } from 'src/apis/api-utilities';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { Tso } from 'src/apis/monolith/types';
import { TsoAssignmentDocuments } from 'src/apis/shipper-registration/types';
import { Download } from 'src/components/domain-specifics/download';
import { PublicRequiredDocumentsCard } from 'src/components/domain-specifics/public-required-documents';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Successful } from 'src/hooks/use-axios';
import styled from 'styled-components';

type Props = {
  assignmentDocuments: TsoAssignmentDocuments;
  tso: Tso;
  registrationInformationDocumentUrl?: string;
  assignmentService?: Successful<AssignmentServiceRequest>;
};

export const TsoDocumentList: FC<Props> = ({
  assignmentDocuments,
  tso,
  registrationInformationDocumentUrl,
  assignmentService,
}) => {
  return (
    <PublicRequiredDocumentsCard
      gtcUrl={tso.gtcUrl}
      countryCode={tso.address.countryCode}
      registrationInformationDocumentUrl={registrationInformationDocumentUrl}
      assignmentService={assignmentService}
    >
      <AssignmentDocuments assignmentDocuments={assignmentDocuments} />
    </PublicRequiredDocumentsCard>
  );
};

// eslint-disable-next-line no-restricted-syntax
export enum DeliveryMethod {
  Digital = 'DIGITAL',
  Post = 'POST',
  Fax = 'FAX',
  PostDigital = 'DIGITAL_AND_POST',
  None = '',
}

type Template = {
  templateId: string;
  templateFileName: string;
  _links: Links<'self'>;
};

type AdditionalDocument = {
  registrationType: DeliveryMethod;
  additionalDocumentName: string;
  template: Template | undefined;
  customConfiguration: string | null;
  url: string | undefined;
};

const AssignmentDocuments: FC<{
  assignmentDocuments: TsoAssignmentDocuments;
}> = ({ assignmentDocuments }) => {
  const creditRatingCertification =
    assignmentDocuments.creditRatingCertification;
  const creditRatingCertificationAgency =
    assignmentDocuments.creditRatingCertificationAgency;
  const creditSolvencyCheckDocument =
    assignmentDocuments.creditSolvencyCheckDocument;
  const mostRecentInterimFinancialReport =
    assignmentDocuments.mostRecentInterimFinancialReport;
  const mostRecentFullYearFinancialReport =
    assignmentDocuments.mostRecentFullYearFinancialReport;
  const currentCompanyReport = assignmentDocuments.currentCompanyReport;
  const companyRegisterExtract = assignmentDocuments.companyRegisterExtract;
  const prismaShipperAdminPowerOfAttorney =
    assignmentDocuments.prismaShipperAdminPowerOfAttorney;
  const additionalDocuments = assignmentDocuments.additionalDocuments;
  const additionalUserDocuments = assignmentDocuments.additionalUserDocuments;

  const isEmpty =
    !companyRegisterExtract &&
    !currentCompanyReport &&
    !mostRecentFullYearFinancialReport &&
    !mostRecentInterimFinancialReport &&
    !creditSolvencyCheckDocument &&
    !creditRatingCertification &&
    !creditRatingCertificationAgency &&
    !prismaShipperAdminPowerOfAttorney &&
    (!additionalDocuments || additionalDocuments.length === 0) &&
    (!additionalUserDocuments || additionalUserDocuments.length === 0);

  return (
    <>
      <p>
        Following documents are required when requesting assignment with this
        operator:
      </p>

      {isEmpty ? (
        <p>There are no documents.</p>
      ) : (
        <>
          <Title
            allNone={
              !companyRegisterExtract &&
              !currentCompanyReport &&
              !mostRecentFullYearFinancialReport &&
              !mostRecentInterimFinancialReport &&
              !creditSolvencyCheckDocument &&
              !creditRatingCertification &&
              !creditRatingCertificationAgency &&
              (!additionalDocuments ||
                additionalDocuments.every(
                  (document) =>
                    document.registrationType === DeliveryMethod.None
                ))
            }
          >
            New Organisation Must Provide
          </Title>

          {companyRegisterExtract && (
            <TextItem
              title="Company Register Extract"
              deliveryMethod={companyRegisterExtract}
            />
          )}

          {currentCompanyReport && (
            <TextItem
              title="Current Company Report"
              deliveryMethod={currentCompanyReport}
            />
          )}

          {mostRecentFullYearFinancialReport && (
            <TextItem
              title="Most Recent (Full-Year) Financial Report"
              deliveryMethod={mostRecentFullYearFinancialReport}
            />
          )}

          {mostRecentInterimFinancialReport && (
            <TextItem
              title="Most Recent (Interim) Financial Report"
              deliveryMethod={mostRecentInterimFinancialReport}
            />
          )}

          {creditSolvencyCheckDocument && (
            <TextItem
              title="Credit/Solvency Check document"
              deliveryMethod={creditSolvencyCheckDocument}
            />
          )}

          {creditRatingCertification && (
            <TextItem
              title="Credit Rating Certification"
              deliveryMethod={creditRatingCertification}
            />
          )}

          {creditRatingCertificationAgency && (
            <TextItem
              title="Credit Rating Certification (+ Agency)"
              deliveryMethod={creditRatingCertificationAgency}
            />
          )}

          {prismaShipperAdminPowerOfAttorney && (
            <TextItem
              title="PoA"
              deliveryMethod={prismaShipperAdminPowerOfAttorney}
            />
          )}

          {additionalDocuments &&
            additionalDocuments.map((document, index) => (
              <Item
                key={`additional-document-${index}`}
                document={document as AdditionalDocument}
              />
            ))}

          {additionalUserDocuments && (
            <>
              <Title>New Users Must Provide</Title>

              {additionalUserDocuments.map((document, index) => (
                <Item
                  key={`additional-user-document-${index}`}
                  document={document as AdditionalDocument}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

const deliveryMethodLabel = {
  [DeliveryMethod.Digital]: 'Digital',
  [DeliveryMethod.Post]: 'Post',
  [DeliveryMethod.PostDigital]: 'Post & Digital',
  [DeliveryMethod.Fax]: 'Fax',
  [DeliveryMethod.None]: '',
};

const SpacedStack = styled(Stack)`
  margin-top: 1rem;
`;

const Title: FC<{ allNone?: boolean; children: ReactNode }> = ({
  children,
  allNone = false,
}) => (
  <SpacedStack flow="column" justifyContent="space-between">
    <h4>{children}</h4>
    {!allNone && <h4>via</h4>}
  </SpacedStack>
);

const TextItem: FC<{ title: string; deliveryMethod: DeliveryMethod }> = ({
  title,
  deliveryMethod,
}) => (
  <Stack flow="column" justifyContent="space-between">
    <p>
      <FontAwesomeIcon icon={faFilePdf} /> {title}
    </p>
    <p>{deliveryMethodLabel[deliveryMethod]}</p>
  </Stack>
);

type Document = {
  registrationType: DeliveryMethod;
  additionalDocumentName: string;
  template?: Template;
  url?: string;
};

const Item: FC<{
  document: Document;
}> = (props) => {
  const {
    document: { additionalDocumentName, registrationType, url, template },
  } = props;

  if (!url && !template) {
    return (
      <TextItem
        title={additionalDocumentName}
        deliveryMethod={registrationType}
      />
    );
  }

  if (url) {
    return (
      <Stack flow="column" justifyContent="space-between">
        <ExternalLink href={url}>
          <FontAwesomeIcon icon={faExternalLink} aria-hidden />{' '}
          {additionalDocumentName}
        </ExternalLink>

        <p>{deliveryMethodLabel[registrationType]}</p>
      </Stack>
    );
  }

  return (
    <Stack flow="column" justifyContent="space-between">
      <Download iconPlacement="left" url={template!._links.self.href}>
        {additionalDocumentName}
      </Download>

      <p>{deliveryMethodLabel[registrationType]}</p>
    </Stack>
  );
};
