import { FC, ReactNode } from 'react';
import { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import { OptionalRequiredDocumentsRequest } from 'src/apis/assignment-service/use-optional-public-required-documents';
import { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { DirectFcfsInfoTableRequest } from 'src/apis/direct-fcfs/use-direct-fcfs-info-table';
import { AuthenticatedMonolithUser } from 'src/apis/monolith/types';
import { OrganisationAssignmentsRequest } from 'src/apis/monolith/use-organisation-assignments';
import { TsoRequest } from 'src/apis/monolith/use-tso';
import { UserAssignmentRequest } from 'src/apis/monolith/use-user-assignment';
import { useOptionalOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { PublicOperatorDetailsRequest } from 'src/apis/organisation-service/use-public-operator-details';
import { OptionalShipperRegistrationRequest } from 'src/apis/shipper-registration/use-shipper-registration';
import { AssignmentDocumentsRequest } from 'src/apis/shipper-registration/use-tso-assignment-documents';
import { TsoRegistrationContactRequest } from 'src/apis/shipper-registration/use-tso-registration-contact';
import { Card } from 'src/components/data-display/card';
import { GeneralOperatorInformation } from 'src/components/domain-specifics/general-operator-information';
import { OperatorContactDetails } from 'src/components/domain-specifics/operator-conatct-details';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { SpinnerContainer } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { UsePortal } from 'src/hooks/use-portal';
import { useTitle } from 'src/hooks/use-title';
import { AssignmentStatus } from 'src/pages/market-information/players/operators/details/assignment-status';
import { DirectFcFsTable } from 'src/pages/market-information/players/operators/details/direct-fcfs-table';
import { LsoContacts } from 'src/pages/market-information/players/operators/details/lso-contacts';
import { MarkDownArea } from 'src/pages/market-information/players/operators/details/markdown-area';
import { CompanyRequiredDocuments } from 'src/pages/market-information/players/operators/details-without-monolith/company-required-documents';

type Props = {
  actionPortal: UsePortal;
  tso: Successful<TsoRequest>;
  operatorDetails: Successful<PublicOperatorDetailsRequest>;
  shipperRegistration: OptionalShipperRegistrationRequest;
  tsoRegistrationContact: TsoRegistrationContactRequest;
  assignmentDocuments: Successful<AssignmentDocumentsRequest>;
  userAssignment?: Successful<UserAssignmentRequest>;
  organisationAssignments?: Successful<OrganisationAssignmentsRequest>;
  caMaOperator?: OperatorRequest;
  infoTableRequest?: DirectFcfsInfoTableRequest;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  publicRequiredDocuments: Successful<OptionalRequiredDocumentsRequest>;
};

const Page: FC<Props> = ({
  actionPortal,
  tso,
  shipperRegistration,
  tsoRegistrationContact,
  assignmentDocuments,
  userAssignment,
  organisationAssignments,
  caMaOperator,
  infoTableRequest,
  operatorDetails,
  assignmentInfo,
  publicRequiredDocuments,
}) => {
  useTitle(
    `Market Participants - ${operatorDetails.response.data.registeredAddress.companyName}`
  );
  const organisationService = useOptionalOrganisationService();
  const registrationInformationDocumentUrl =
    shipperRegistration.response?.data._links?.registrationInformationDocument
      ?.href;

  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const organisationAssignment = organisationAssignments?.response.data.find(
    (assignment) => assignment.tso.id === tso.response.data.id
  );
  const operatorDetailsResponse = operatorDetails.response.data;

  const operatorId = tso.response.data.organizationId;
  const contactsUrl =
    organisationService.response?.data._links.getLsoContacts?.href.replace(
      '{organisationId}',
      operatorId
    );

  return (
    <SpinnerContainer pending={tso.pending}>
      {monolithUser?.isShipper &&
        organisationAssignment &&
        actionPortal.renderContent(<LinkToAssignments user={monolithUser} />)}

      <Stack gap={2}>
        <Card>
          <GeneralOperatorInformation
            operatorDetails={operatorDetailsResponse}
          />
          {/* TODO: remove this check after shipper registration is removed */}
          <OperatorContactDetails assignmentInfo={assignmentInfo} />
        </Card>

        {operatorDetailsResponse.markdownContent && (
          <MarkDownArea
            markdownContent={operatorDetailsResponse.markdownContent}
          />
        )}

        {userAssignment && monolithUser && (
          <AssignmentStatus
            userAssignment={userAssignment}
            organisationAssignment={organisationAssignment}
            tso={tso}
            user={monolithUser}
            caMaOperator={caMaOperator}
          />
        )}

        {contactsUrl && <LsoContacts url={contactsUrl} />}

        <CompanyRequiredDocuments
          tsoDetails={operatorDetailsResponse}
          publicRequiredDocuments={publicRequiredDocuments}
        />

        <DirectFcFsTable infoTableRequest={infoTableRequest} />
      </Stack>
    </SpinnerContainer>
  );
};

export const RegistrationDataStack: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { minTablet } = useBreakpoints();
  return (
    <>
      <Spacer />
      <Stack
        templateColumns={minTablet ? '1fr 1fr 1fr' : '1fr'}
        gap={1.5}
        alignItems="start"
      >
        {children}
      </Stack>
      <Spacer />
    </>
  );
};

const LinkToAssignments: FC<{ user: AuthenticatedMonolithUser }> = ({
  user,
}) => {
  const userIsShipperAdmin = user.role === 'SHIPPER_ADMIN';
  if (userIsShipperAdmin) {
    return (
      <div data-testid="assignment-link">
        Go to{' '}
        <Link mode="default-underlined" to="/operators/assignments/overview">
          Assignments
        </Link>{' '}
        to edit your status.
      </div>
    );
  } else {
    return (
      <div data-testid="assignment-link">
        Go to{' '}
        <Link mode="default-underlined" to="/operators/assignments/overview">
          Assignments
        </Link>{' '}
        for further details.
      </div>
    );
  }
};

export default Page;
