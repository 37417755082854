import { useEffect } from 'react';
import {
  AssignedUsersParams,
  AssignedUsersResponse,
} from 'src/apis/assignment-service/types';

import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Props = {
  url: string;
  params: AssignedUsersParams;
};

export function useAssignedUsers({ url, params }: Props) {
  const assignedUsers = useAxios(
    (axios, baseConfig, { url, params }: Props) =>
      axios.request<AssignedUsersResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const assignedUsersUrl = urlTemplate.parse(url).expand({});
    assignedUsers.execute({ url: assignedUsersUrl, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);

  return assignedUsers;
}

export type AssignedUsersRequest = ReturnType<typeof useAssignedUsers>;
